import React from 'react'
import { useIntl } from 'react-intl'
import { Common_Error_Title, Common_Error_Description } from '../../translations/messages'
import { useRouteError } from 'react-router'
import { logError } from '../../lib/logError'

export const PageErrorElement = () => {
  const intl = useIntl()
  const error = useRouteError()

  if (error) {
    logError(new Error('Unexpected error', error))
  }

  return (
    <div className="centerFlex mt-lg">
      <div className="card p-lg prose">
        <h1>{intl.formatMessage(Common_Error_Title)}</h1>
        <p>{intl.formatMessage(Common_Error_Description)}</p>
      </div>
    </div>
  )
}
