import React, { FunctionComponent, useEffect, useState } from 'react'
import { intl } from '../../lib/intl'
import {
  Common_Email,
  Filter_ClearFilters,
  Filter_FilterByEmail,
  Filter_FilterByRegion,
  Filter_Unassigned
} from '../../translations/messages'
import { FormattedMessage } from 'react-intl'
import { hasAdminRole } from '../../lib/authentication'
import { useDebounce } from 'use-debounce'
import { useRouteLoaderData } from 'react-router-dom'

type Props = {
  regionFilter: AdminPageFilter['regionFilter']
  onTextFilterChange: (filter: AdminPageFilter['textFilter']) => void
  onRegionFilterChanged: (filter: AdminPageFilter['regionFilter']) => void
  onClearFilter: () => void
}

export const Filter: FunctionComponent<Props> = ({
  regionFilter,
  onTextFilterChange,
  onRegionFilterChanged,
  onClearFilter
}) => {
  const { locationsGroupedByRegion } = useRouteLoaderData('admin') as AdminPageLoaderType
  const [text, setText] = useState('')
  const [value] = useDebounce(text, 500)

  useEffect(() => {
    onTextFilterChange(value)
  }, [onTextFilterChange, value])

  const regions = locationsGroupedByRegion && Object.keys(locationsGroupedByRegion)

  const toggleRegionFilter = (region: string) => {
    const newRegionFilter = regionFilter.includes(region)
      ? regionFilter.filter(currentRegion => currentRegion !== region)
      : [...regionFilter, region]

    onRegionFilterChanged(newRegionFilter)
  }

  const regionInFilter = (region: string) => regionFilter.includes(region)

  return (
    <>
      <div className="grid grid-cols-8 gap-md">
        <label htmlFor="filter" className="label col-span-1 row-start-1">
          <FormattedMessage id={Filter_FilterByEmail.id} defaultMessage={Filter_FilterByEmail.defaultMessage} />
        </label>

        <input
          id="filter"
          type="text"
          className="col-span-3 row-start-1"
          placeholder={intl.formatMessage(Common_Email)}
          onChange={e => {
            setText(e.target.value)
          }}
        />

        <div className="label row-start-2 col-span-1">
          <FormattedMessage id={Filter_FilterByRegion.id} defaultMessage={Filter_FilterByRegion.defaultMessage} />
        </div>
        <div className="row-start-2 col-span-7 flex flex-wrap -m-xs">
          {regions.map(region => (
            <button
              key={region}
              onClick={() => toggleRegionFilter(region)}
              disabled={!hasAdminRole()}
              className={`tag m-xs ${regionInFilter(region) ? 'tag-orange' : ''}`}
            >
              {region}
            </button>
          ))}
          <button
            onClick={() => toggleRegionFilter('unassigned')}
            className={`tag m-xs ${regionInFilter('unassigned') ? 'tag-orange' : ''}`}
          >
            <FormattedMessage id={Filter_Unassigned.id} defaultMessage={Filter_Unassigned.defaultMessage} />
          </button>
        </div>
      </div>

      <div className="flex justify-end mt-sm">
        <button className="btn" onClick={onClearFilter}>
          <FormattedMessage id={Filter_ClearFilters.id} defaultMessage={Filter_ClearFilters.defaultMessage} />
        </button>
      </div>
    </>
  )
}
