/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/tomracollection/Fleet%20Portal%20Prod/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/tomracollection/Fleet%20Portal%20Prod/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'fleetportalprod';

export const ApiKey: Record<Environment, string> = {
  fleetportalprod: '41408bff6fecb781c68eabc946a60251'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'web',
    versionId: '31ca2b77-2123-488b-ae60-c0695369bc7e'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  acceptance_rate_threshold?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  cleaning_threshold?: number;
  email_type?: string;
  notification_enabled?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_regions_access?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_stores_access?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  uptime_threshold?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | admin, user |
   */
  user_role?: "admin" | "user";
}

export interface StoreDataExportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_weeks: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StoreDataExportFailed implements BaseEvent {
  event_type = 'Store Data Export Failed';
}

export class StoreDataExported implements BaseEvent {
  event_type = 'Store Data Exported';

  constructor(
    public event_properties: StoreDataExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ThresholdsChanged implements BaseEvent {
  event_type = 'Thresholds Changed';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Store Data Export Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Fleet%20Portal%20Prod/events/main/latest/Store%20Data%20Export%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  storeDataExportFailed(
    options?: EventOptions,
  ) {
    return this.track(new StoreDataExportFailed(), options);
  }

  /**
   * Store Data Exported
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Fleet%20Portal%20Prod/events/main/latest/Store%20Data%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_weeks)
   * @param options Amplitude event options.
   */
  storeDataExported(
    properties: StoreDataExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StoreDataExported(properties), options);
  }

  /**
   * Thresholds Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Fleet%20Portal%20Prod/events/main/latest/Thresholds%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  thresholdsChanged(
    options?: EventOptions,
  ) {
    return this.track(new ThresholdsChanged(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
