import React, { useCallback, useState } from 'react'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { ArrowRightIcon, HomeIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'
import {
  AdminPage_AddNewUser,
  AdminPage_DeleteUsers,
  AdminPage_MobileWarning,
  AdminPage_Title
} from '../../translations/messages'
import { AddUserDialog } from './AddUserDialog'
import { Filter } from './Filter'
import { UserList } from './UserList'
import { DeleteUserDialog } from './DeleteUserDialog'
import { hasAdminRole } from '../../lib/authentication'
import { RegionAdminList } from './RegionAdminList'
import { fetchLocations, fetchRegionAdmins, fetchUserAccessList } from '../../services/access-service'
import { locationSort } from '../../lib/util'
import { LocationType, RegionAdminType, UserAccessType } from '../../dataTypes'
import { AnimatePresence } from 'framer-motion'

declare global {
  type AdminPageLoaderType = {
    userAccess: UserAccessType[]
    locations: LocationType[]
    regionAdmins: RegionAdminType[]
    locationsGroupedByRegion: { [key: string]: LocationType[] }
  }

  type AdminPageFilter = {
    textFilter: string
    regionFilter: string[]
  }
}

export const adminDataLoader = async () => {
  const userAccess = await fetchUserAccessList().run()
  const regionAdmins = await fetchRegionAdmins().catch(error => {
    if (!hasAdminRole() && error.status === 403) {
      return []
    }

    throw error
  })

  const locations = await fetchLocations()

  const locationsGroupedByRegion = locations
    .sort(locationSort)
    .reduce((previousValue: Record<string, LocationType[]>, currentValue: LocationType) => {
      const region = currentValue.region
      if (!previousValue[region]) previousValue[region] = []
      previousValue[region].push(currentValue)
      return previousValue
    }, {})

  return { userAccess, regionAdmins, locations, locationsGroupedByRegion }
}

export const AdminPage = () => {
  const { myRegions } = useRouteLoaderData('root') as RootLoaderData
  const [usersToDelete, setUsersToDelete] = useState<UserAccessType[]>([])
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false)

  const [activeFilter, setActiveFilter] = useState<AdminPageFilter>({
    textFilter: '',
    regionFilter: !hasAdminRole() ? [...myRegions, 'unassigned'] : []
  })

  const onTextFilterChange = useCallback((value: AdminPageFilter['textFilter']) => {
    setActiveFilter(prev => ({ ...prev, textFilter: value }))
  }, [])

  const clearActiveFilter = () => {
    setActiveFilter({
      textFilter: '',
      regionFilter: hasAdminRole() ? [] : activeFilter.regionFilter // Prevent region admins from setting empty region filter
    })
  }

  const toggleUserToDelete = (user: UserAccessType) => {
    const userStagedForDeletion = usersToDelete.filter(existingUser => existingUser.userId === user.userId).length > 0

    const newUsersToRemove = userStagedForDeletion
      ? usersToDelete.filter(existingUser => existingUser.userId !== user.userId)
      : [...usersToDelete, user]

    setUsersToDelete(newUsersToRemove)
  }

  return (
    <React.Fragment>
      <AnimatePresence>
        {showAddUserDialog && <AddUserDialog onClose={() => setShowAddUserDialog(false)} />}

        {showRemoveUserDialog && (
          <DeleteUserDialog
            usersToDelete={usersToDelete}
            onClose={() => {
              setShowRemoveUserDialog(false)
              setUsersToDelete([])
            }}
          />
        )}
      </AnimatePresence>

      <div className="my-md max-w-screen-2xl mx-auto">
        <div className="card px-md py-sm flex items-center space-x-sm">
          <Link to="/">
            <HomeIcon width="1.5rem" height="1.5rem" />
          </Link>
          <ArrowRightIcon width="1rem" height="1rem" />
          <span>
            <FormattedMessage id={AdminPage_Title.id} defaultMessage={AdminPage_Title.defaultMessage} />
          </span>
        </div>

        <div>
          <div className="flex items-center justify-between p-md sticky top-0 -mx-[0.125rem] bg-grey-light">
            <h1 className="text-xl">
              <FormattedMessage id={AdminPage_Title.id} defaultMessage={AdminPage_Title.defaultMessage} />
            </h1>

            <div className="grid grid-cols-2 gap-x-md">
              <button className="btn btn-primary-dark" onClick={() => setShowAddUserDialog(true)}>
                <FormattedMessage id={AdminPage_AddNewUser.id} defaultMessage={AdminPage_AddNewUser.defaultMessage} />
              </button>
              <button
                className="btn btn-primary-danger"
                disabled={usersToDelete.length === 0}
                onClick={() => setShowRemoveUserDialog(true)}
              >
                <FormattedMessage id={AdminPage_DeleteUsers.id} defaultMessage={AdminPage_DeleteUsers.defaultMessage} />
              </button>
            </div>
          </div>

          <div className="px-md">
            <div className="md:hidden alert alert-warning m-auto my-sm">
              <FormattedMessage
                id={AdminPage_MobileWarning.id}
                defaultMessage={AdminPage_MobileWarning.defaultMessage}
              />
            </div>

            <Filter
              regionFilter={activeFilter.regionFilter}
              onTextFilterChange={onTextFilterChange}
              onRegionFilterChanged={value => setActiveFilter(prev => ({ ...prev, regionFilter: value }))}
              onClearFilter={clearActiveFilter}
            />

            {hasAdminRole() && <RegionAdminList activeFilter={activeFilter} />}

            <UserList
              activeFilter={activeFilter}
              usersToDelete={usersToDelete}
              toggleUserToDelete={toggleUserToDelete}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
