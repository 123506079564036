import React, { useEffect, useState } from 'react'
import { authenticatedFetchData } from '../../lib/fetch'
import { API_HOST } from '../../lib/environment'
import { useLoaderData } from 'react-router'
import { eachDayOfInterval, format, subDays } from 'date-fns'

const DATE_FORMAT = 'dd.MM.yy'

type KpiCount = {
  source: 'ALDI_NORD' | 'TOMRA'
  date: string
  count: number
}

type UploadStatusType = {
  locationsPerSource: { ALDI_NORD: number; TOMRA: number }
  machinesPerSource: { ALDI_NORD: number; TOMRA: number }
  kpiCountsPerDayFromSource: KpiCount[]
}

type SortedUpdates = { [key: string]: { TOMRA: KpiCount | null; ALDI_NORD: KpiCount | null } }

const lastThirtyDaysInterval = {
  start: subDays(new Date(), 30),
  end: new Date()
}

const lastThirtyDays = eachDayOfInterval(lastThirtyDaysInterval)
  .reverse()
  .reduce((acc, date) => {
    return { ...acc, [format(date, DATE_FORMAT)]: { ALDI_NORD: null, TOMRA: null } }
  }, {} as SortedUpdates)

export function uploadStatusLoader() {
  return authenticatedFetchData(`${API_HOST}/status/uploads`)
}

export const UploadStatusPage = () => {
  const data = useLoaderData() as UploadStatusType

  const [machinesPerSource, setMachinesPerSource] = useState<{ ALDI_NORD: number; TOMRA: number }>()
  const [locationsPerSource, setLocationsPerSource] = useState<{ ALDI_NORD: number; TOMRA: number }>()
  const [updateStatuses, setUpdateStatuses] = useState<SortedUpdates>()

  useEffect(() => {
    if (!data) {
      return
    }

    setLocationsPerSource(data.locationsPerSource)
    setMachinesPerSource(data.machinesPerSource)

    const statuses = data.kpiCountsPerDayFromSource.reduce((acc, kpi) => {
      const formattedDate = format(new Date(kpi.date), DATE_FORMAT)
      const accDate = acc[formattedDate]
      const { source, count } = kpi

      acc[formattedDate] = { ...accDate, [source]: { source, count } }

      return acc
    }, lastThirtyDays)

    setUpdateStatuses(statuses)
  }, [data])

  return (
    <div className="mx-auto max-w-screen-sm py-lg">
      {updateStatuses ? (
        <>
          <h1 className="text-xl mb-md">Upload status</h1>

          <div className="card mb-md">
            <h2 className="text-md mx-lg pt-md mb-sm">Locations</h2>

            <table className="table">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>
                    <div className="text-right">Locations</div>
                  </th>
                  <th>
                    <div className="text-right">Machines</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aldi Nord</td>
                  <td>
                    <div className="text-right">{locationsPerSource?.ALDI_NORD || '-'}</div>
                  </td>
                  <td>
                    <div className="text-right">{machinesPerSource?.ALDI_NORD || '-'}</div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>TOMRA</td>
                  <td>
                    <div className="text-right">{locationsPerSource?.TOMRA || '-'}</div>
                  </td>
                  <td>
                    <div className="text-right">{machinesPerSource?.TOMRA || '-'}</div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card">
            <h2 className="text-md mb-sm mx-lg pt-md">KPIs</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Source</th>
                  <th>
                    <div className="text-right">Machines</div>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(updateStatuses).map(date => (
                  <React.Fragment key={`al-${date}`}>
                    <tr>
                      <td className="font-bold">{date}</td>
                      <td>Aldi Nord</td>
                      <td>
                        <div className="text-right">{updateStatuses[date].ALDI_NORD?.count || '-'}</div>
                      </td>
                      <td></td>
                    </tr>
                    <tr className="border-2" style={{ borderBottomColor: 'var(--colors-dust)' }}>
                      <td></td>
                      <td>TOMRA</td>
                      <td>
                        <div className="text-right">{updateStatuses[date].TOMRA?.count || '-'}</div>
                      </td>
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>
          <div className="loadingSpinner"></div>
        </div>
      )}
    </div>
  )
}
