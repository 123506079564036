import { RvmDetailType } from '../../dataTypes'
import { FormattedMessage, FormattedRelativeTime } from 'react-intl'
import { StoreStatus_LastUpdatedDate } from '../../translations/messages'
import React from 'react'

export const MostRecentUpdate = ({ rvmDetails }: { rvmDetails: RvmDetailType[] }) => {
  const mostRecentlyUpdatedRvm = rvmDetails
    .filter(rvm => rvm.mostRecentStats !== undefined)
    .sort((a, b) => new Date(a.mostRecentStats).getTime() - new Date(b.mostRecentStats).getTime())[0]
  const startOfCurrentDay = new Date().setUTCHours(0, 0, 0)
  const lastUpdate = new Date(mostRecentlyUpdatedRvm.mostRecentStats)
  return (
    <div className="text-center">
      <FormattedMessage
        id={StoreStatus_LastUpdatedDate.id}
        defaultMessage={StoreStatus_LastUpdatedDate.defaultMessage}
      />
      <FormattedRelativeTime
        value={(lastUpdate.getTime() - startOfCurrentDay) / 1000}
        updateIntervalInSeconds={1}
        numeric="auto"
      />
    </div>
  )
}
