import { API_HOST } from '../lib/environment'
import { authenticatedFetchData } from '../lib/fetch'

export const fetchOverallStatus = () => authenticatedFetchData(`${API_HOST}/status/v1`)

export const fetchOverallCsv = (startDate: string, endDate: string) =>
  authenticatedFetchData(`${API_HOST}/status/v1/csv?start=${startDate}&end=${endDate}`).then(csv => ({ csv }))

export const fetchStoreStatus = (region: string, locationId: string) =>
  authenticatedFetchData(`${API_HOST}/status/v1/${region}/${locationId}`)

export const fetchStoreCsv = (region: string, locationId: string, startDate: string, endDate: string) =>
  authenticatedFetchData(`${API_HOST}/status/v1/${region}/${locationId}/csv?start=${startDate}&end=${endDate}`).then(
    csv => ({ csv })
  )
