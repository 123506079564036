import React from 'react'
import { useIntl } from 'react-intl'
import { PageNotFound_Title } from '../translations/messages'

export const PageNotFound = () => {
  const intl = useIntl()

  return (
    <div className="my-md max-w-screen-2xl mx-auto px-md">
      <h1 className="text-lg">{intl.formatMessage(PageNotFound_Title)}</h1>
    </div>
  )
}
