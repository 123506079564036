import React, { FunctionComponent, useState } from 'react'
import { intl } from '../../../lib/intl'
import {
  Common_Acceptance,
  Common_AcceptedVolume,
  Common_AvgDowntime,
  Common_AvgUptime,
  Common_Cleaning,
  Common_Cleanings,
  Common_UptimeRate,
  OverallStatus_AvgTime
} from '../../../translations/messages'
import { CardSwipeContainer, SwipeCard } from '../../shared/CardSwipe'
import { Rate } from '../../shared'
import { secondsToHoursAndMinutes, secondsToMinutesAndSeconds } from '../../../lib/util'
import { OverallStatusType } from '../../../dataTypes'
import { useRouteLoaderData } from 'react-router-dom'

const NavTab: FunctionComponent<{
  id: string
  isActive: boolean
  label: string
  onClick: () => void
}> = ({ id, isActive, label, onClick }) => {
  return (
    <button
      id={id}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      aria-selected={isActive}
      onClick={onClick}
      className={`w-full rounded py-sm text-sm transition active:scale-95 ${
        isActive ? 'bg-green-light font-bold' : 'bg-fog'
      }`}
    >
      {label}
    </button>
  )
}

const pages = ['acceptance', 'uptime', 'cleaning']

export const StatusOverview: FunctionComponent<{ status: OverallStatusType }> = ({ status }) => {
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { acceptanceRateThreshold, uptimeRateThreshold, cleaningRateThreshold } = user?.settings || {}
  const [[page, direction], setPage] = useState(['acceptance', 0])

  const swipe = (fromPage: string, newDirection: number) => {
    const newPage = pages[pages.indexOf(page) + newDirection]

    if (newPage) {
      setPage([newPage, newDirection])
    }
  }

  const jumpToPage = (nextPage: string) => {
    const currentPageIndex = pages.indexOf(page)
    const nextPageIndex = pages.indexOf(nextPage)
    setPage([nextPage, currentPageIndex > nextPageIndex ? -1 : 1])
  }

  return (
    <>
      <ul role="tablist" className="grid grid-cols-3 gap-x-md">
        <li role="presentation" className="col-span-1">
          <NavTab
            id="tab-acceptance"
            isActive={page === 'acceptance'}
            label={intl.formatMessage(Common_Acceptance)}
            onClick={() => jumpToPage('acceptance')}
          />
        </li>
        <li role="presentation" className="col-span-1">
          <NavTab
            id="tab-uptime"
            isActive={page === 'uptime'}
            label={intl.formatMessage(Common_UptimeRate)}
            onClick={() => jumpToPage('uptime')}
          />
        </li>
        <li role="presentation" className="col-span-1">
          <NavTab
            id="tab-cleaning"
            isActive={page === 'cleaning'}
            label={intl.formatMessage(Common_Cleaning)}
            onClick={() => jumpToPage('cleaning')}
          />
        </li>
      </ul>

      <CardSwipeContainer>
        <SwipeCard
          pageKey="acceptance"
          currentPage={page}
          direction={direction}
          paginate={newDirection => swipe('acceptance', newDirection)}
        >
          <div className="card p-md grid grid-cols-2 gap-md" role="region" aria-labelledby="tab-acceptance">
            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_Acceptance)}</h2>
              <div className="text-md">
                <Rate value={status.acceptanceRate} threshold={acceptanceRateThreshold} />
              </div>
            </div>

            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_AcceptedVolume)}</h2>
              <div className="text-md">{status.acceptedVolume.toLocaleString('no')}</div>
            </div>
          </div>
        </SwipeCard>

        <SwipeCard
          pageKey="uptime"
          currentPage={page}
          direction={direction}
          paginate={newDirection => swipe('uptime', newDirection)}
        >
          <div className="card p-md grid grid-cols-3 gap-md" role="region" aria-labelledby="tab-uptime">
            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_UptimeRate)}</h2>
              <div className="text-md">
                <Rate value={status.uptimeRate} threshold={uptimeRateThreshold} />
              </div>
            </div>

            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_AvgUptime)}</h2>
              <div className="text-md">{secondsToHoursAndMinutes(status.averageUptimeInSeconds)}</div>
            </div>

            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_AvgDowntime)}</h2>
              <div className="text-md">{secondsToHoursAndMinutes(status.averageDowntimeInSeconds)}</div>
            </div>
          </div>
        </SwipeCard>

        <SwipeCard
          pageKey="cleaning"
          currentPage={page}
          direction={direction}
          paginate={newDirection => swipe('cleanings', newDirection)}
        >
          <div className="card p-md grid grid-cols-3 gap-md" role="region" aria-labelledby="tab-cleaning">
            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_Cleaning)}</h2>
              <div className="text-md">
                <Rate value={status.cleaningRate} threshold={cleaningRateThreshold} />
              </div>
            </div>
            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(Common_Cleanings)}</h2>
              <div className="text-md">
                {status.cleaningCount}/{status.expectedCleaningCount}
              </div>
            </div>

            <div className="text-center col-span-1">
              <h2 className="text-sm font-bold mb-sm">{intl.formatMessage(OverallStatus_AvgTime)}</h2>
              <div className="text-md">{secondsToMinutesAndSeconds(status.averageCleaningDurationInSeconds)}</div>
            </div>
          </div>
        </SwipeCard>
      </CardSwipeContainer>
    </>
  )
}
