import { SortOrderType, SortType, StoreSummaryType } from '../../dataTypes'

export const summarySort = (sortBy: SortType, sortOrder: SortOrderType) => {
  return (a: StoreSummaryType, b: StoreSummaryType) => {
    if (sortBy === 'locationName' && a.location.locationName && b.location.locationName) {
      return sortOrder === 'asc' ? compareLocationNames(a, b) : compareLocationNames(b, a)
    }

    return sortOrder === 'asc'
      ? sortNumericValues(sortBy as keyof StoreSummaryType, a, b)
      : sortNumericValues(sortBy as keyof StoreSummaryType, b, a)
  }
}

const compareLocationNames = (a: StoreSummaryType, b: StoreSummaryType) => {
  if (a.location.region === b.location.region) {
    return parseInt(a.location.locationId) - parseInt(b.location.locationId)
  }

  return a.location.region < b.location.region ? -1 : a.location.region > b.location.region ? 1 : 0
}

const sortNumericValues = (sortBy: keyof StoreSummaryType, a: StoreSummaryType, b: StoreSummaryType) => {
  return a[sortBy]! > b[sortBy]! ? 1 : -1
}
