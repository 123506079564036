import { RvmDetailType, RvmWeeklySummaryType } from '../../dataTypes'
import { Rate } from '../shared'
import React from 'react'
import { getWeekNumber } from '../../lib/util'
import { intl } from '../../lib/intl'
import { RvmStatusTable_Now, RvmStatusTable_Week, StoreStatus_Rvm } from '../../translations/messages'
import { FormattedMessage } from 'react-intl'
import { RvmTooltip } from './RvmTooltip'
import { useRouteLoaderData } from 'react-router-dom'

type PropsType = {
  rvmWeeklySummariesMap: { [rvmSerial: string]: RvmWeeklySummaryType[] }
  rvmDetails: RvmDetailType[]
  field: 'acceptanceRate' | 'cleaningRate' | 'uptimeRate'
}

export const RvmStatusTable = ({ rvmWeeklySummariesMap, rvmDetails, field }: PropsType) => {
  const currentWeekNumber = getWeekNumber(new Date())
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const settings = user?.settings
  const rvmWeeklySummaries = Object.values(rvmWeeklySummariesMap)[0] ?? []

  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            <FormattedMessage id={StoreStatus_Rvm.id} defaultMessage={StoreStatus_Rvm.defaultMessage} />
          </th>
          {rvmWeeklySummaries.map(rvmWeeklySummary => (
            <th key={rvmWeeklySummary.weekNumber}>
              {rvmWeeklySummary.weekNumber === currentWeekNumber
                ? intl.formatMessage(RvmStatusTable_Now)
                : intl.formatMessage(RvmStatusTable_Week, { weekNumber: rvmWeeklySummary.weekNumber })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(rvmWeeklySummariesMap).map(rvmSerial => (
          <tr key={rvmSerial}>
            <td>
              <RvmTooltip rvmDetail={rvmDetails.find(rvm => rvm.rvmSerial === rvmSerial)!} />
            </td>
            {rvmWeeklySummariesMap[rvmSerial]?.map(weeklySummary => (
              <td key={weeklySummary.weekNumber}>
                {field === 'acceptanceRate' ? (
                  <Rate value={weeklySummary.acceptanceRate} threshold={settings?.acceptanceRateThreshold} />
                ) : field === 'cleaningRate' ? (
                  <Rate value={weeklySummary.cleaningRate} threshold={settings?.cleaningRateThreshold} />
                ) : field === 'uptimeRate' ? (
                  <Rate value={weeklySummary.uptimeRate} threshold={settings?.uptimeRateThreshold} />
                ) : null}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
