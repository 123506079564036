import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  UserAccessPage_NewPassword_ButtonText,
  UserAccessPage_NewPassword_Failure,
  UserAccessPage_NewPassword_Success
} from '../../translations/messages'
import { useParams } from 'react-router-dom'
import { AlertIcon } from '../../icons'
import { generateNewPassword } from '../../services/access-service'

export const GeneratePassword = () => {
  const { userId } = useParams() as { userId: string }
  const intl = useIntl()
  const [newPassword, setNewPassword] = useState('')
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'success' | 'failure'>('idle')

  const onGenerateNewPassword = async (e: React.FormEvent) => {
    try {
      e.preventDefault()

      setRequestStatus('pending')

      const newPassword = await generateNewPassword(userId)

      setNewPassword(newPassword)
      setRequestStatus('success')
    } catch (error) {
      setRequestStatus('failure')
    }
  }

  return (
    <div className="flex flex-col space-y-md pr-md">
      <form className="self-end" onSubmit={onGenerateNewPassword}>
        <button className="btn btn-primary-dark" disabled={requestStatus === 'pending'}>
          {intl.formatMessage(UserAccessPage_NewPassword_ButtonText)}
        </button>
      </form>

      {requestStatus === 'pending' ? (
        <div>
          <div className="loadingSpinner" />
        </div>
      ) : newPassword ? (
        <div className="p-md rounded-half bg-fog">
          {intl.formatMessage(UserAccessPage_NewPassword_Success, { newPassword })}
        </div>
      ) : requestStatus === 'failure' ? (
        <div className="font-bold text-red-dark flex space-x-sm items-center">
          <AlertIcon width="1rem" height="1rem" />
          <span>{intl.formatMessage(UserAccessPage_NewPassword_Failure)}</span>
        </div>
      ) : null}
    </div>
  )
}
