import { ampli } from '../ampli'
import { AMPLITUDE_CONFIG } from './environment'
import { logError } from './logError'

const disabled = !!window.location.host.match(/(:\d{4})/gi)
export const initAmplitude = () => {
  const defaultTracking = disabled
    ? false
    : {
        pageViews: true,
        sessions: true,
        formInteractions: false,
        fileDownloads: false
      }

  try {
    ampli.load({
      disabled,
      client: {
        apiKey: AMPLITUDE_CONFIG.apiKey,
        configuration: {
          defaultTracking
        }
      }
    })
  } catch (error: unknown) {
    logError(new Error('Unable to load Amplitude'), error as Error)
  }
}

export const getAmplitudeEmailType = (email: string) => {
  const emailLowerCase = email.toLowerCase()

  if (emailLowerCase.includes('tomra')) {
    return 'tomra'
  } else if (
    emailLowerCase.includes('gmail') ||
    emailLowerCase.includes('yahoo') ||
    emailLowerCase.includes('outlook') ||
    emailLowerCase.includes('icloud') ||
    emailLowerCase.includes('hotmail')
  ) {
    return 'personal'
  } else {
    return 'other'
  }
}
