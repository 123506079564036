import { LocationType, StoreSummaryType } from '../dataTypes'

export const secondsToHoursAndMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`
}

export const secondsToMinutesAndSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return minutes > 0 ? `${minutes}m ${remainingSeconds}s` : `${seconds}s`
}

export const getWeekNumber = (date: Date) => {
  // Copy date so don't modify original
  const dateCopy = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  dateCopy.setUTCDate(dateCopy.getUTCDate() + 4 - (dateCopy.getUTCDay() || 7))
  // Get first day of year
  const yearStart = new Date(Date.UTC(dateCopy.getUTCFullYear(), 0, 1))
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((dateCopy.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)

  return weekNo
}

export const weeksBetween = (startDate: Date, endDate: Date) => {
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000
  return Math.floor((endDate.getTime() - startDate.getTime()) / oneWeekInMilliseconds)
}

export const locationSort = (locationA: LocationType, locationB: LocationType) => {
  if (locationA.region === locationB.region) {
    return parseInt(locationA.locationId) - parseInt(locationB.locationId)
  } else {
    return locationA.region < locationB.region ? -1 : locationA.region > locationB.region ? 1 : 0
  }
}

export const countRegions = (storeSummaries: StoreSummaryType[]) => {
  const regions = storeSummaries.map(store => store.location.region)
  return new Set(regions).size
}
