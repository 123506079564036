import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { getLocale } from './locale'
import englishMessage from '../translations/locales/en-GB.json'
import germanMessages from '../translations/locales/de.json'

let activeLanguage

try {
  const savedLanguage = window.localStorage.getItem('activeLanguage')
  activeLanguage = savedLanguage || getLocale()
} catch (error) {
  activeLanguage = getLocale()
}

if (document.documentElement) {
  document.documentElement.lang = activeLanguage
}

const messages: { [key: string]: any } = {
  en: englishMessage,
  de: germanMessages
}

export const intl: IntlShape = createIntl(
  {
    locale: activeLanguage,
    defaultLocale: 'en',
    messages: messages[activeLanguage]
  },
  createIntlCache()
)
