import React, { FunctionComponent, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'

type Props = {
  labeledBy: string
  describedBy: string
  children?: ReactNode
}

export const Dialog: FunctionComponent<Props> = ({ children, labeledBy, describedBy }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' })

  return ReactDOM.createPortal(
    <motion.div className="dialog-overlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <motion.div
        className={isDesktopOrLaptop ? 'dialog-content max-w-screen-md min-w-screen-md' : 'w-[90%]'}
        role="alertdialog"
        aria-modal="true"
        aria-labelledby={labeledBy}
        aria-describedby={describedBy}
        initial={{ opacity: 0, y: '-200%' }}
        animate={{ opacity: 1, y: isDesktopOrLaptop ? '0' : '50%' }}
        exit={{ opacity: 0, y: '-200%' }}
      >
        {children}
      </motion.div>
    </motion.div>,
    document.getElementById('root') as HTMLDivElement
  )
}
