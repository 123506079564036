export const LanguageSelect_English = {
  id: 'language_select.english',
  defaultMessage: 'English'
}

export const LanguageSelect_German = {
  id: 'language_select.german',
  defaultMessage: 'German'
}

export const Common_Acceptance = {
  id: 'common.acceptance_rate',
  defaultMessage: 'Acceptance'
}

export const Common_AcceptedVolume = {
  id: 'common.accepted_volume',
  defaultMessage: 'Accepted Volume'
}

export const Common_UptimeRate = {
  id: 'common.uptime_rate',
  defaultMessage: 'Uptime'
}

export const Common_AvgUptime = {
  id: 'common.avg_uptime',
  defaultMessage: 'Avg. uptime'
}

export const Common_AvgDowntime = {
  id: 'common.avg_downtime',
  defaultMessage: 'Avg. downtime'
}

export const Common_Cleaning = {
  id: 'common.cleaning_rate',
  defaultMessage: 'Cleaning'
}

export const Common_Cleanings = {
  id: 'common.cleanings',
  defaultMessage: 'Cleanings'
}

export const Common_Submit = {
  id: 'common.submit',
  defaultMessage: 'Submit'
}

export const Common_Email = {
  id: 'common.email',
  defaultMessage: 'Email'
}

export const Common_Cancel = {
  id: 'common.cancel',
  defaultMessage: 'Cancel'
}

export const Common_Close = {
  id: 'common.close',
  defaultMessage: 'Close'
}

export const Common_Deadtime = {
  id: 'common.deadtime',
  defaultMessage: 'Deadtime'
}

export const Common_Maintenance = {
  id: 'common.maintenance',
  defaultMessage: 'Maintenance'
}

export const Common_Other = {
  id: 'common.other',
  defaultMessage: 'Other'
}

export const Common_ExportData = {
  id: 'common.export_data',
  defaultMessage: 'Export store data'
}

export const Common_ExportData_Failure = {
  id: 'common.export_data_failure',
  defaultMessage: 'Failed to export store data'
}

export const TermsAndConditions_Title = {
  id: 'terms_and_conditions.title',
  defaultMessage: 'Confidential Information'
}

export const TermsAndConditions_Description_Text_One = {
  id: 'terms_and_conditions.description.text.one',
  defaultMessage:
    'The information in this application, Fleet Portal is confidential and available for You as authorized personnel only.'
}

export const TermsAndConditions_Description_Text_Two = {
  id: 'terms_and_conditions.description.text.two',
  defaultMessage:
    'Before signing in, You acknowledge that have undergone the internal training and read the mandatory guidelines. '
}

export const TermsAndConditions_Description_Link = {
  id: 'terms_and_conditions.description.link',
  defaultMessage: '(link)'
}

export const TermsAndConditions_Highlights_Title = {
  id: 'terms_and_conditions.highlights.title',
  defaultMessage: 'Highlights:'
}

export const TermsAndConditions_Highlights_Item_One = {
  id: 'terms_and_conditions.highlights.item.one',
  defaultMessage:
    'The information You get access to herein shall not be shared with anyone who is not authorized (including non-authorized colleagues).'
}

export const TermsAndConditions_Highlights_Item_Two = {
  id: 'terms_and_conditions.highlights.item.two',
  defaultMessage: 'The information shall only be used for the approved purpose.'
}

export const TermsAndConditions_Highlights_Item_Three = {
  id: 'terms_and_conditions.highlights.item.three',
  defaultMessage: 'The user account is personal, and all logins are logged for 2 years.'
}

export const TermsAndConditions_Checkbox_Label = {
  id: 'terms_and_conditions.checkbox.label',
  defaultMessage: 'I understand and accept the above.'
}

export const TermsAndConditions_Continue = {
  id: 'terms_and_conditions.continue',
  defaultMessage: 'Continue'
}

export const OverallStatus_Title = {
  id: 'overall_status.title',
  defaultMessage: 'Overall status'
}

export const OverallStatus_LastFourWeeks = {
  id: 'overall_status.last_four_weeks',
  defaultMessage: 'Last 4 weeks'
}

export const OverallStatus_AvgTime = {
  id: 'overall_status.avg_time',
  defaultMessage: 'Avg. time'
}

export const OverallStatus_MyStores = {
  id: 'overall_status.my_stores',
  defaultMessage: 'My Stores'
}

export const OverallStatus_Store = {
  id: 'overall_status.store',
  defaultMessage: 'Store'
}

export const OverallStatus_NoStoresAlert = {
  id: 'overall_status.no_stores_alert',
  defaultMessage:
    'You do not have access to any available stores. If your stores do not yet have data in the Fleet Portal, they will become available to you as soon as the data is uploaded.'
}

export const OverallStatus_SortBy = {
  id: 'overall_status.sort_by',
  defaultMessage: 'Sort by {key}'
}

export const StoreStatus_Rvm = {
  id: 'store_status.rvm',
  defaultMessage: 'RVM'
}

export const StoreStatus_LastUpdatedDate = {
  id: 'store_status.last_updated_date',
  defaultMessage: 'Updated '
}

export const AcceptanceRateStatus_RejectionRate = {
  id: 'acceptance_rate_status.rejection_rate',
  defaultMessage: 'Rejection Rate'
}

export const AcceptanceRateStatus_RejectedVolume = {
  id: 'acceptance_rate_status.rejected_volume',
  defaultMessage: 'Rejected Volume'
}

export const CleaningRateStatus_TimeSpent = {
  id: 'cleaning_rate_status.time_spent',
  defaultMessage: 'Time Spent'
}

export const CleaningRateStatus_AvgDuration = {
  id: 'cleaning_rate_status.avg_duration',
  defaultMessage: 'Avg. Duration'
}

export const UptimeRateStatus_Downtime = {
  id: 'uptime_rate_status.downtime_rate',
  defaultMessage: 'Downtime'
}

export const RvmStatusTable_Now = {
  id: 'rvm_status_table.now',
  defaultMessage: 'Now'
}

export const RvmStatusTable_Week = {
  id: 'rvm_status_table.week',
  defaultMessage: 'w{weekNumber}'
}

export const Settings_Title = {
  id: 'settings.title',
  defaultMessage: 'Settings'
}

export const Settings_GeneralSettings = {
  id: 'settings.general_settings',
  defaultMessage: 'General Settings'
}

export const Settings_Language = {
  id: 'settings.language',
  defaultMessage: 'Language'
}

export const Settings_Notifications = {
  id: 'settings.notifications',
  defaultMessage: 'Notifications'
}

export const Settings_EmailNotifications = {
  id: 'settings.email_notifications',
  defaultMessage: 'Email notifications'
}

export const Settings_Thresholds = {
  id: 'settings.thresholds',
  defaultMessage: 'Thresholds'
}

export const Settings_Calculations = {
  id: 'settings.calculations',
  defaultMessage: 'Calculations'
}

export const Settings_AcceptanceRate = {
  id: 'settings.acceptance_rate',
  defaultMessage: 'Acceptance rate (%)'
}
export const Settings_Uptime = {
  id: 'settings.uptime',
  defaultMessage: 'Uptime (%)'
}

export const Settings_Cleaning = {
  id: 'settings.cleaning',
  defaultMessage: 'Cleaning (%)'
}

export const Settings_AcceptanceDescription = {
  id: 'settings.acceptance_description',
  defaultMessage: 'Accepted containers divided by total containers'
}

export const Settings_UptimeDescription = {
  id: 'settings.uptime_description',
  defaultMessage: 'Uptime rate = Uptime / (Uptime + Downtime Maintenance + Downtime Deadtime + Downtime Other)'
}

export const Settings_CleaningDescription = {
  id: 'settings.cleaning_description',
  defaultMessage: 'Successful cleanings divided by expected number of cleanings'
}

export const Settings_DeadtimeDescription = {
  id: 'settings.deadtime_description',
  defaultMessage:
    'Downtime calculated due to machine errors like: front machine errors (printer errors, cheat errors), sorting errors, backroom/bin errors (transport conveyer errors, compactor errors) or other sensor errors.'
}

export const Settings_MaintenanceDescription = {
  id: 'settings.maintenance_description',
  defaultMessage:
    'Downtime calculated due to machine interruptions like: door(s) opened, bin full, out of paper, table full etc. This is downtime related to daily operations. Improved response time on these interruptions will reduce this downtime.'
}

export const Settings_OtherDescription = {
  id: 'settings.other_description',
  defaultMessage: 'All other undefined errors causing machine downtime.'
}

export const Settings_Admin = {
  id: 'settings.admin',
  defaultMessage: 'Admin'
}

export const Settings_Logout = {
  id: 'settings.logout',
  defaultMessage: 'Logout'
}

export const AdminPage_Title = {
  id: 'admin_page.title',
  defaultMessage: 'Administrate access'
}

export const AdminPage_AddNewUser = {
  id: 'admin_page.add_new_user',
  defaultMessage: 'Add new user'
}

export const AdminPage_DeleteUsers = {
  id: 'admin_page.delete_users',
  defaultMessage: 'Delete users'
}

export const AdminPage_MobileWarning = {
  id: 'admin_page.mobile_warning',
  defaultMessage: 'This page is not optimized for mobile devices'
}

export const AddUserDialog_SuccessfullyCreatedUser_Title = {
  id: 'add_user_dialog.successfully_created_user.title',
  defaultMessage: 'Successfully created new user with email {email}'
}

export const AddUserDialog_SuccessfullyCreatedUser_Description = {
  id: 'add_user_dialog.successfully_created_user.description',
  defaultMessage:
    'Share the temporary password with the new user. The user must then log in to create a new password and verify their account before it becomes available'
}

export const AddUserDialog_TemporaryPassword = {
  id: 'add_user_dialog.temporary_password',
  defaultMessage: 'Temporary password: {password}'
}

export const AddUserDialog_AddAnotherUser = {
  id: 'add_user_dialog.add_another_user',
  defaultMessage: 'Add another user'
}

export const AddUserDialog_FailureMessage = {
  id: 'add_user_dialog.failure_message',
  defaultMessage: 'Failed to create new user'
}

export const AddUserDialog_ConflictMessage = {
  id: 'add_user_dialog.conflict_message',
  defaultMessage: 'User already exists'
}

export const AddUserDialog_Email = {
  id: 'add_user_dialog.email',
  defaultMessage: 'Email:'
}

export const DeleteUserDialog_Title = {
  id: 'delete_user_dialog.title',
  defaultMessage: 'Delete Users'
}

export const DeleteUserDialog_Success = {
  id: 'delete_user_dialog.success',
  defaultMessage: 'Successfully deleted users!'
}

export const DeleteUserDialog_Failure = {
  id: 'delete_user_dialog.failure',
  defaultMessage: 'Failed to delete users!'
}

export const DeleteUserDialog_UserList = {
  id: 'delete_user_dialog.user_list',
  defaultMessage: 'The following user(s) will be deleted:'
}

export const DeleteUserDialog_AreYouSure = {
  id: 'delete_user_dialog.are_you_sure',
  defaultMessage: 'Are you sure?'
}

export const DeleteUserDialog_Delete = {
  id: 'delete_user_dialog.delete',
  defaultMessage: 'Delete'
}

export const Filter_FilterByEmail = {
  id: 'filter.filter_by_email',
  defaultMessage: 'Filter:'
}

export const Filter_FilterByRegion = {
  id: 'filter.filter_by_region',
  defaultMessage: 'Filter by region:'
}

export const Filter_ClearFilters = {
  id: 'filter.clear_filters',
  defaultMessage: 'Clear Filters'
}

export const Filter_Unassigned = {
  id: 'filter.unassigned',
  defaultMessage: 'Unassigned'
}

export const UserList_Title = {
  id: 'user_list.title',
  defaultMessage: 'Users'
}

export const RegionAdminList_Title = {
  id: 'region_admin_list.title',
  defaultMessage: 'Region Admins'
}

export const UserAccessPage_FailedToFindUser = {
  id: 'user_access_page.failed_to_find_user',
  defaultMessage: 'Failed to find user matching {userId}'
}

export const UserAccessPage_RegionsToAdminister = {
  id: 'user_access_page.regions_to_administer',
  defaultMessage: 'Regions to administer'
}

export const UserAccessPage_Regions = {
  id: 'user_access_page.regions',
  defaultMessage: 'Regions'
}

export const UserAccessPage_NewPassword_ButtonText = {
  id: 'user_access_page.new_password.button_text',
  defaultMessage: 'Generate New Password'
}

export const UserAccessPage_NewPassword_Success = {
  id: 'user_access_page.new_password.success',
  defaultMessage: 'New Password: {newPassword}'
}

export const UserAccessPage_NewPassword_Failure = {
  id: 'user_access_page.new_password.failure',
  defaultMessage: 'Failed to generate new password'
}

export const Common_Menu = {
  id: 'common.menu',
  defaultMessage: 'Menu'
}

export const Common_Error_Title = {
  id: 'common.error.title',
  defaultMessage: 'Something went wrong'
}

export const Common_Error_Description = {
  id: 'common.error.description',
  defaultMessage: 'Our developers have been notified of the error. Please try again later.'
}

export const PageNotFound_Title = {
  id: 'pagenotfound.title',
  defaultMessage: 'Page not found'
}

export const ExportData_Start = {
  id: 'exportdata.start',
  defaultMessage: 'Start date'
}

export const ExportData_End = {
  id: 'exportdata.end',
  defaultMessage: 'End date'
}

export const ExportData_StartBeforeEndError = {
  id: 'exportdata.startbeforeend',
  defaultMessage: 'Start date must be before end date'
}

export const Common_Months = {
  id: 'common.months',
  defaultMessage: '{value} {value, plural, one {month} other {months}}'
}
