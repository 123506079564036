type EnvironmentConfig = {
  ENV: string
  STAGE: string
  CONFIG_BY_STAGE: { [key: string]: { CONFIG_BY_ENV: { [key: string]: any }; AMPLITUDE_CONFIG: { apiKey: string } } }
}

// @ts-ignore
const envConfig = window.__ENVIRONMENT__ as EnvironmentConfig

const { ENV: ENVIRONMENT, STAGE, CONFIG_BY_STAGE } = envConfig
const { AMPLITUDE_CONFIG } = CONFIG_BY_STAGE[STAGE]

const { KEYCLOAK_HOST, API_HOST } = CONFIG_BY_STAGE[STAGE].CONFIG_BY_ENV[ENVIRONMENT]

export { ENVIRONMENT, KEYCLOAK_HOST, API_HOST, STAGE, AMPLITUDE_CONFIG }
