import { Rate } from '../shared'
import React from 'react'
import { StoreStatusType } from '../../dataTypes'
import { RvmStatusTable } from './RvmStatusTable'
import { FormattedMessage } from 'react-intl'
import {
  AcceptanceRateStatus_RejectedVolume,
  AcceptanceRateStatus_RejectionRate,
  Common_Acceptance,
  Common_AcceptedVolume
} from '../../translations/messages'
import { useRouteLoaderData } from 'react-router-dom'

export const AcceptanceRateStatus = ({ storeStatus }: { storeStatus: StoreStatusType }) => {
  const { acceptanceRate, acceptedVolume, rejectedVolume, rvmWeeklySummariesMap, rvmDetails } = storeStatus
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { acceptanceRateThreshold } = user?.settings ?? {}

  const textContainerStyles = `text-center font-bold text-sm`

  return (
    <div className="card m-sm">
      <h2 className="p-lg text-lg">
        <FormattedMessage id={Common_Acceptance.id} defaultMessage={Common_Acceptance.defaultMessage} />
      </h2>
      <div className="flex flex-wrap justify-around">
        <div className="flex flex-col">
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Acceptance.id} defaultMessage={Common_Acceptance.defaultMessage} />
            </div>
            <span className="flex centerFlex">
              <Rate value={acceptanceRate} threshold={acceptanceRateThreshold} />
            </span>
          </div>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_AcceptedVolume.id} defaultMessage={Common_AcceptedVolume.defaultMessage} />
            </div>
            <span className="centerFlex">{acceptedVolume.toLocaleString('no')}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage
                id={AcceptanceRateStatus_RejectionRate.id}
                defaultMessage={AcceptanceRateStatus_RejectionRate.defaultMessage}
              />
            </div>
            <span className="centerFlex">
              <Rate value={acceptanceRate ? 100 - acceptanceRate : null} />
            </span>
          </div>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage
                id={AcceptanceRateStatus_RejectedVolume.id}
                defaultMessage={AcceptanceRateStatus_RejectedVolume.defaultMessage}
              />
            </div>
            <span className="centerFlex">{rejectedVolume.toLocaleString('no')}</span>
          </div>
        </div>
      </div>
      <RvmStatusTable rvmWeeklySummariesMap={rvmWeeklySummariesMap} rvmDetails={rvmDetails} field="acceptanceRate" />
    </div>
  )
}
