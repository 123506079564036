import React, { FunctionComponent } from 'react'
import { OverallStatus_LastFourWeeks } from '../../../translations/messages'
import { intl } from '../../../lib/intl'
import { OverallStatusType } from '../../../dataTypes'
import { StatusOverview } from './StatusOverview'
import { motion } from 'framer-motion'
import { StoresList } from './StoresList'
import { ExportCSV } from '../ExportCSV'

type Props = {
  status: OverallStatusType
}

export const OverallStatusMobile: FunctionComponent<Props> = ({ status }) => {
  return (
    <div className="m-md flex flex-col space-y-md relative">
      <h1 className="text-lg">{intl.formatMessage(OverallStatus_LastFourWeeks)}</h1>

      <StatusOverview status={status} />

      <ExportCSV />

      <motion.div layout="position">
        <StoresList storeSummaries={status.storeSummaries} />
      </motion.div>
    </div>
  )
}
