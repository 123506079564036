import React, { FunctionComponent, ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const swipeConfidenceThreshold = 10000

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

type Props = {
  children?: ReactNode
}

export const CardSwipeContainer: FunctionComponent<Props> = ({ children }) => {
  return <AnimatePresence initial={false}>{children}</AnimatePresence>
}

export const SwipeCard: FunctionComponent<{
  children: ReactNode
  pageKey: string
  currentPage: string
  direction: number
  paginate: (newDirection: number) => void
}> = ({ children, pageKey, currentPage, direction, paginate }) => {
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      }
    }
  }

  return pageKey === currentPage ? (
    <motion.div
      key={pageKey}
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x)

        if (swipe < -swipeConfidenceThreshold) {
          paginate(1)
        } else if (swipe > swipeConfidenceThreshold) {
          paginate(-1)
        }
      }}
    >
      {children}
    </motion.div>
  ) : null
}
