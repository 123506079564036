import React from 'react'
import { FormattedMessage } from 'react-intl'
import { UserAccessPage_Regions } from '../../../translations/messages'
import { LayoutGroup, motion } from 'framer-motion'
import { RegionAccordion } from './RegionAccordion'
import { hasAdminRole } from '../../../lib/authentication'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import { UserAccessType } from '../../../dataTypes'
import { ActionFunction } from 'react-router'
import { addLocation, removeLocation, toggleAccessForRegion } from '../../../services/access-service'

export const toggleUserAccessLocationAction: ActionFunction = async ({ request, params }) => {
  const { userId } = params as { userId: string }
  const { region, locationId } = Object.fromEntries(await request.formData()) as {
    region: string
    locationId: string
  }

  if (request.method === 'PUT') {
    return await addLocation(userId, region, locationId)
  }

  if (request.method === 'DELETE') {
    return await removeLocation(userId, region, locationId)
  }

  return null
}

export const toggleUserAccessRegionAction: ActionFunction = async ({ request, params }) => {
  const { userId } = params as { userId: string }
  const { region } = Object.fromEntries(await request.formData()) as { region: string }

  return await toggleAccessForRegion(userId, region)
}

export const UserAccess = () => {
  const { userId } = useParams() as { userId: string }
  const { myRegions } = useRouteLoaderData('root') as RootLoaderData
  const { userAccess, locationsGroupedByRegion } = useRouteLoaderData('admin') as AdminPageLoaderType

  const myAdministeredRegions = hasAdminRole() ? Object.keys(locationsGroupedByRegion) : myRegions

  const access = userAccess.find(_userAccess => _userAccess.userId === userId)

  return (
    <motion.div layout="position" className="card p-lg mt-md">
      <h2 className="text-lg mb-md">
        <FormattedMessage id={UserAccessPage_Regions.id} defaultMessage={UserAccessPage_Regions.defaultMessage} />
      </h2>

      <LayoutGroup id="regionList">
        <motion.ul layout="position" className="grid grid-cols-8 gap-y-lg gap-x-md">
          {Object.keys(locationsGroupedByRegion).map(region => {
            return (
              <motion.li layout="position" key={region}>
                <RegionAccordion
                  region={region}
                  userAccess={access as UserAccessType}
                  locationsInRegion={locationsGroupedByRegion[region]}
                  disabled={!myAdministeredRegions.includes(region)}
                />
              </motion.li>
            )
          })}
        </motion.ul>
      </LayoutGroup>
    </motion.div>
  )
}
