import { useParams, useRouteLoaderData } from 'react-router-dom'
import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon, HomeIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'
import { AdminPage_Title, UserAccessPage_FailedToFindUser } from '../../translations/messages'
import { UserAccess } from './user-access/UserAccess'
import { RegionAdministration } from './region-administration/RegionAdministration'
import { GeneratePassword } from './GeneratePassword'

export const UserAccessPage = () => {
  const { userId } = useParams() as { userId: string }
  const { userAccess } = useRouteLoaderData('admin') as AdminPageLoaderType

  const access = userAccess.find(_userAccess => _userAccess.userId === userId)

  if (access === undefined) {
    return (
      <div className="alert alert-danger centerAbsolute">
        <FormattedMessage
          id={UserAccessPage_FailedToFindUser.id}
          defaultMessage={UserAccessPage_FailedToFindUser.defaultMessage}
          values={{ userId }}
        />
      </div>
    )
  }

  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="card px-md py-sm flex items-center space-x-sm my-md">
        <Link to="/">
          <HomeIcon width="1.5rem" height="1.5rem" />
        </Link>

        <ArrowRightIcon width="1rem" height="1rem" />

        <Link to="/admin" className="link text-black">
          <FormattedMessage id={AdminPage_Title.id} defaultMessage={AdminPage_Title.defaultMessage} />
        </Link>

        <ArrowRightIcon width="1rem" height="1rem" />
        <span>{access.email}</span>
      </div>

      <div className="flex items-start justify-between mb-md">
        <h1 className="text-xl mx-md">{access.email}</h1>
        <GeneratePassword />
      </div>

      <RegionAdministration />

      <UserAccess />
    </div>
  )
}
