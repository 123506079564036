import { API_HOST } from '../lib/environment'
import { UserSettingsType } from '../dataTypes'
import { authenticatedFetchData } from '../lib/fetch'

export const fetchUserData = () => authenticatedFetchData(`${API_HOST}/user/v1`)

export const putUserSettings = (userSettings: UserSettingsType) =>
  authenticatedFetchData(`${API_HOST}/user/v1/settings`, {
    method: 'PUT',
    body: JSON.stringify(userSettings)
  })
