import React, { useEffect } from 'react'
import { OverallStatusType, StoreSummaryType, UserType } from '../../dataTypes'
import { fetchOverallStatus } from '../../services'
import { countRegions, locationSort } from '../../lib/util'
import { useMediaQuery } from 'react-responsive'
import { OverallStatusMobile } from './mobile/OverallStatusMobile'
import { OverallStatusDesktop } from './desktop/OverallStatusDesktop'
import { LoaderFunction, useLoaderData } from 'react-router'
import { OverallStatus_NoStoresAlert } from '../../translations/messages'
import { useIntl } from 'react-intl'
import { LayoutGroup } from 'framer-motion'
import { ampli } from '../../ampli'
import { getAmplitudeEmailType } from '../../lib/amplitudeUtils'
import { useRouteLoaderData } from 'react-router-dom'
import { hasAdminRole } from '../../lib/authentication'

const storeSummarySort = (storeSummaryA: StoreSummaryType, storeSummaryB: StoreSummaryType) =>
  locationSort(storeSummaryA.location, storeSummaryB.location)

export const overallStatusLoader: LoaderFunction = () => {
  return fetchOverallStatus().then(overallStatus => ({
    ...overallStatus,
    storeSummaries: overallStatus.storeSummaries.sort(storeSummarySort)
  }))
}

export const OverallStatusPage = () => {
  const intl = useIntl()
  const isAdmin = hasAdminRole()
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' })
  const status = useLoaderData() as OverallStatusType
  const { user } = useRouteLoaderData('root') as { user: UserType }

  useEffect(() => {
    ampli.identify(undefined, {
      user_role: isAdmin ? 'admin' : 'user',
      acceptance_rate_threshold: user.settings.acceptanceRateThreshold,
      cleaning_threshold: user.settings.cleaningRateThreshold,
      uptime_threshold: user.settings.uptimeRateThreshold,
      email_type: getAmplitudeEmailType(user.email),
      notification_enabled: user.settings.emailNotificationsEnabled,
      number_stores_access: status.storeSummaries.length,
      number_regions_access: countRegions(status.storeSummaries)
    })
  }, [isAdmin, user, status])

  return (
    <>
      {status.storeSummaries.length === 0 && (
        <div className="alert alert-warning max-w-screen-sm mx-auto mt-md">
          {intl.formatMessage(OverallStatus_NoStoresAlert)}
        </div>
      )}

      <LayoutGroup id="status">
        {isDesktopOrLaptop ? <OverallStatusDesktop status={status} /> : <OverallStatusMobile status={status} />}
      </LayoutGroup>
    </>
  )
}
