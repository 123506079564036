import { StoreStatusType } from '../../dataTypes'
import { Rate } from '../shared'
import { secondsToHoursAndMinutes } from '../../lib/util'
import React, { useState } from 'react'
import { RvmStatusTable } from './RvmStatusTable'
import { FormattedMessage } from 'react-intl'
import {
  Common_UptimeRate,
  Common_AvgUptime,
  UptimeRateStatus_Downtime,
  Common_AvgDowntime,
  Common_Deadtime,
  Common_Maintenance,
  Common_Other
} from '../../translations/messages'
import { useRouteLoaderData } from 'react-router-dom'

export const UptimeRateStatus = ({ storeStatus }: { storeStatus: StoreStatusType }) => {
  const {
    uptimeRate,
    uptimeInSeconds,
    deadtimeDowntimeInSeconds,
    maintenanceDowntimeInSeconds,
    otherDowntimeInSeconds,
    rvmWeeklySummariesMap,
    rvmDetails
  } = storeStatus
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { uptimeRateThreshold } = user?.settings ?? {}
  const [showGranularDowntime, setShowGranularDowntime] = useState(true)

  const totalDowntimeInSeconds = deadtimeDowntimeInSeconds + maintenanceDowntimeInSeconds + otherDowntimeInSeconds
  const portionOfTotalDowntime = (downtime: number) => Math.round((100 * downtime) / totalDowntimeInSeconds)

  const toggleShowGranularDowntime = () => {
    setShowGranularDowntime(!showGranularDowntime)
  }

  const textContainerStyles = `text-center font-bold text-sm`

  return (
    <div className="card m-sm">
      <h2 className="p-lg text-lg">
        <FormattedMessage id={Common_UptimeRate.id} defaultMessage={Common_UptimeRate.defaultMessage} />
      </h2>
      <div className="flex flex-wrap justify-around">
        <div className="flex flex-col">
          <div className="m-sm text-center">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_UptimeRate.id} defaultMessage={Common_UptimeRate.defaultMessage} />
            </div>
            <span className="">
              <Rate value={uptimeRate} threshold={uptimeRateThreshold} />
            </span>
          </div>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_AvgUptime.id} defaultMessage={Common_AvgUptime.defaultMessage} />
            </div>
            <span className="centerFlex">{secondsToHoursAndMinutes(uptimeInSeconds)}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <button className="border rounded-half hover:bg-grey-light" onClick={toggleShowGranularDowntime}>
            <div className="m-sm">
              <div className={textContainerStyles}>
                <FormattedMessage
                  id={UptimeRateStatus_Downtime.id}
                  defaultMessage={UptimeRateStatus_Downtime.defaultMessage}
                />
              </div>
              <span className="centerFlex">
                <Rate value={uptimeRate ? 100 - uptimeRate : null} />
              </span>
            </div>
          </button>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_AvgDowntime.id} defaultMessage={Common_AvgDowntime.defaultMessage} />
            </div>
            <span className="centerFlex">{secondsToHoursAndMinutes(totalDowntimeInSeconds)}</span>
          </div>
        </div>
      </div>
      {showGranularDowntime && totalDowntimeInSeconds > 0 && (
        <div className="py-sm bg-grey-light flex justify-around">
          <div>
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Deadtime.id} defaultMessage={Common_Deadtime.defaultMessage} />
            </div>
            <span className="centerFlex">
              <Rate value={portionOfTotalDowntime(deadtimeDowntimeInSeconds)} />
            </span>
          </div>
          <div>
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Maintenance.id} defaultMessage={Common_Maintenance.defaultMessage} />
            </div>
            <span className="centerFlex">
              <Rate value={portionOfTotalDowntime(maintenanceDowntimeInSeconds)} />
            </span>
          </div>
          <div>
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Other.id} defaultMessage={Common_Other.defaultMessage} />
            </div>
            <span className="centerFlex">
              <Rate value={portionOfTotalDowntime(otherDowntimeInSeconds)} />
            </span>
          </div>
        </div>
      )}
      <RvmStatusTable rvmWeeklySummariesMap={rvmWeeklySummariesMap} rvmDetails={rvmDetails} field="uptimeRate" />
    </div>
  )
}
