import React, { useEffect } from 'react'
import { putUserSettings } from '../services/user-service'
import { UserType } from '../dataTypes'
import { useIntl } from 'react-intl'
import {
  Common_Acceptance,
  Common_Cleaning,
  Common_Email,
  Common_Submit,
  Common_UptimeRate,
  Settings_AcceptanceDescription,
  Settings_AcceptanceRate,
  Settings_Calculations,
  Settings_Cleaning,
  Settings_CleaningDescription,
  Settings_DeadtimeDescription,
  Settings_EmailNotifications,
  Settings_GeneralSettings,
  Settings_Language,
  Settings_Logout,
  Settings_MaintenanceDescription,
  Settings_Notifications,
  Settings_OtherDescription,
  Settings_Thresholds,
  Settings_Title,
  Settings_Uptime,
  Settings_UptimeDescription,
  Common_Deadtime,
  Common_Maintenance,
  Common_Other
} from '../translations/messages'
import { ActionFunction } from 'react-router'
import { LanguageSelect } from './header/LanguageSelect'
import { authStore, getEmailFromToken } from '../lib/authentication'
import { useFetcher, useRouteLoaderData } from 'react-router-dom'
import { ampli } from '../ampli'

const noOp = () => {}

export const settingsAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData()) as { [key: string]: string }

  const updatedSettings = {
    emailNotificationsEnabled: data.emailNotificationsEnabled === 'true',
    acceptanceRateThreshold: parseInt(data.acceptanceRateThreshold),
    uptimeRateThreshold: parseInt(data.uptimeRateThreshold),
    cleaningRateThreshold: parseInt(data.cleaningRateThreshold)
  }

  return await putUserSettings(updatedSettings)
}

export const SettingsPage = () => {
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { settings } = user as UserType
  const intl = useIntl()

  const fetcher = useFetcher()

  const toggleEmailNotifications = () => {
    fetcher.submit(
      // @ts-ignore
      { ...settings, emailNotificationsEnabled: !settings.emailNotificationsEnabled },
      { method: 'put', action: '' }
    )
  }

  useEffect(() => {
    const logThresholdChanges = () => {
      if (
        settings.acceptanceRateThreshold !== parseInt(fetcher.formData?.get('acceptanceRateThreshold') as string) ||
        settings.uptimeRateThreshold !== parseInt(fetcher.formData?.get('uptimeRateThreshold') as string) ||
        settings.cleaningRateThreshold !== parseInt(fetcher.formData?.get('cleaningRateThreshold') as string)
      ) {
        ampli.thresholdsChanged()
      }
    }
    if (fetcher.state === 'submitting') {
      logThresholdChanges()
    }
  }, [fetcher, settings])

  const isLoading = fetcher.state === 'submitting' || fetcher.state === 'loading'

  return (
    <div className="max-w-screen-sm m-auto card p-lg">
      <h1 className="text-xl">{intl.formatMessage(Settings_Title)}</h1>

      <div className="flex flex-col space-y-md">
        <h2 className="text-lg">{intl.formatMessage(Settings_GeneralSettings)}</h2>

        <dl>
          <dt className="label">{intl.formatMessage(Common_Email)}</dt>
          <dd>{getEmailFromToken()}</dd>
        </dl>

        <div>
          <label htmlFor="language-select" className="label">
            {intl.formatMessage(Settings_Language)}
          </label>
          <LanguageSelect />
        </div>

        <fetcher.Form action="" method="put">
          <div className="mt-lg">
            <h3 className="text-lg">{intl.formatMessage(Settings_Notifications)}</h3>
            <div className="flex justify-between items-center py-sm">
              <label htmlFor="toggle-email-notifications-checkbox" className="text-midnight">
                {intl.formatMessage(Settings_EmailNotifications)}
              </label>
              <input
                id="toggle-email-notifications-checkbox"
                type="checkbox"
                className="switch"
                value={settings.emailNotificationsEnabled ? 'true' : 'false'}
                name="emailNotificationsEnabled"
                onChange={isLoading ? noOp : toggleEmailNotifications}
                checked={settings.emailNotificationsEnabled}
                aria-disabled={isLoading}
              />
            </div>
          </div>

          <div className="mt-md">
            <h3 className="text-lg mb-md">{intl.formatMessage(Settings_Thresholds)}</h3>
            <div className="grid grid-cols-6 gap-md items-center py-sm">
              <label htmlFor="acceptance-threshold-input" className="col-span-2">
                {intl.formatMessage(Settings_AcceptanceRate)}
              </label>
              <input
                id="acceptance-threshold-input"
                type="number"
                className="col-span-4"
                name="acceptanceRateThreshold"
                min={0}
                max={100}
                defaultValue={settings.acceptanceRateThreshold}
              />
            </div>
          </div>

          <div className="grid grid-cols-6 gap-md items-center py-sm">
            <label htmlFor="uptime-threshold-input" className="col-span-2">
              {intl.formatMessage(Settings_Uptime)}
            </label>
            <input
              id="uptime-threshold-input"
              type="number"
              className="col-span-4"
              name="uptimeRateThreshold"
              min={0}
              max={100}
              defaultValue={settings.uptimeRateThreshold}
            />
          </div>

          <div className="grid grid-cols-6 gap-md items-center py-sm">
            <label htmlFor="cleaning-threshold-input" className="col-span-2">
              {intl.formatMessage(Settings_Cleaning)}
            </label>
            <input
              id="cleaning-threshold-input"
              type="number"
              className="col-span-4"
              name="cleaningRateThreshold"
              min={0}
              max={100}
              defaultValue={settings.cleaningRateThreshold}
            />
          </div>

          <div className="flex justify-end">
            <button type="submit" className="btn btn-primary-dark" aria-disabled={isLoading}>
              {intl.formatMessage(Common_Submit)}
            </button>
          </div>
        </fetcher.Form>
      </div>

      <div className="mt-md">
        <h2 className="text-lg mb-md">{intl.formatMessage(Settings_Calculations)}</h2>

        <dl>
          <dt className="label">{intl.formatMessage(Common_Acceptance)}</dt>
          <dd>{intl.formatMessage(Settings_AcceptanceDescription)}</dd>

          <dt className="label mt-md">{intl.formatMessage(Common_UptimeRate)}</dt>
          <dd>{intl.formatMessage(Settings_UptimeDescription)}</dd>

          <dt className="label mt-md">{intl.formatMessage(Common_Deadtime)}</dt>
          <dd>{intl.formatMessage(Settings_DeadtimeDescription)}</dd>

          <dt className="label mt-md">{intl.formatMessage(Common_Maintenance)}</dt>
          <dd>{intl.formatMessage(Settings_MaintenanceDescription)}</dd>

          <dt className="label mt-md">{intl.formatMessage(Common_Other)}</dt>
          <dd>{intl.formatMessage(Settings_OtherDescription)}</dd>

          <dt className="label mt-md">{intl.formatMessage(Common_Cleaning)}</dt>
          <dd>{intl.formatMessage(Settings_CleaningDescription)}</dd>
        </dl>
      </div>

      <button onClick={authStore.logout} className="btn btn-primary-danger mt-lg w-full sm:w-auto">
        {intl.formatMessage(Settings_Logout)}
      </button>
    </div>
  )
}
