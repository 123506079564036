import React, { FunctionComponent, useState } from 'react'
import { intl } from '../../../lib/intl'
import {
  Common_Acceptance,
  Common_Cleaning,
  Common_UptimeRate,
  OverallStatus_MyStores,
  OverallStatus_SortBy,
  OverallStatus_Store
} from '../../../translations/messages'
import { SortOrderType, SortType, StoreSummaryType } from '../../../dataTypes'
import { ArrowRightIcon, ArrowUpIcon } from '../../../icons'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { summarySort } from '../sort-helpers'
import { LayoutGroup, motion } from 'framer-motion'
import { Rate } from '../../shared'

const SortTab: FunctionComponent<{
  label: string
  sortKey: SortType
  sortBy: SortType
  sortOrder: SortOrderType
  onClick: () => void
}> = ({ sortKey, label, sortBy, sortOrder, onClick }) => {
  const isActive = sortBy === sortKey

  return (
    <button
      aria-label={intl.formatMessage(OverallStatus_SortBy, { key: label })}
      onClick={onClick}
      className={`rounded py-sm px-md text-sm transition active:scale-95 ${
        isActive ? 'bg-green-light font-bold' : 'bg-fog'
      }`}
    >
      <span className="flex items-center justify-center space-x-sm">
        <span>{label}</span>
        {sortBy === sortKey && (
          <ArrowUpIcon
            className={`transition ${sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
            width="1rem"
            height="1rem"
          />
        )}
      </span>
    </button>
  )
}

export const StoresList: FunctionComponent<{ storeSummaries: StoreSummaryType[] }> = ({ storeSummaries }) => {
  const [sortBy, setSortBy] = useState<SortType>('locationName')
  const [sortOrder, setSortOrder] = useState<SortOrderType>('asc')
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { acceptanceRateThreshold, uptimeRateThreshold, cleaningRateThreshold } = user?.settings || {}

  const sortSummariesByKey = (key: SortType) => {
    if (key === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }

    setSortBy(key)
  }

  return (
    <>
      <h2 className="mt -md text-lg">{intl.formatMessage(OverallStatus_MyStores)}</h2>

      <div className="flex space-x-md my-md overflow-x-scroll no-scrollbar">
        <SortTab
          label={intl.formatMessage(OverallStatus_Store)}
          onClick={() => sortSummariesByKey('locationName')}
          sortKey="locationName"
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        <SortTab
          label={intl.formatMessage(Common_Acceptance)}
          onClick={() => sortSummariesByKey('acceptanceRate')}
          sortKey="acceptanceRate"
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        <SortTab
          label={intl.formatMessage(Common_UptimeRate)}
          onClick={() => sortSummariesByKey('uptimeRate')}
          sortKey="uptimeRate"
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        <SortTab
          label={intl.formatMessage(Common_Cleaning)}
          onClick={() => sortSummariesByKey('cleaningRate')}
          sortKey="cleaningRate"
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </div>

      <ul className="card">
        <LayoutGroup>
          {storeSummaries.sort(summarySort(sortBy, sortOrder)).map(storeSummary => {
            const {
              location: { region, locationName, locationId },
              acceptanceRate,
              uptimeRate,
              cleaningRate
            } = storeSummary

            return (
              <motion.li
                layout="position"
                key={`store-${locationId}-${locationName}`}
                className="grid grid-cols-3 gap-x-md p-md"
              >
                <span className="col-span-1">{locationName}</span>

                {sortBy === 'acceptanceRate' ? (
                  <span className="text-end col-span-1">
                    <Rate value={acceptanceRate} threshold={acceptanceRateThreshold} />
                  </span>
                ) : sortBy === 'uptimeRate' ? (
                  <span className="text-end col-span-1">
                    <Rate value={uptimeRate} threshold={uptimeRateThreshold} />
                  </span>
                ) : sortBy === 'cleaningRate' ? (
                  <span className="text-end col-span-1">
                    <Rate value={cleaningRate} threshold={cleaningRateThreshold} />
                  </span>
                ) : null}

                <Link to={`/status/${region}/${locationId}`} className="flex justify-end col-span-1 col-start-3">
                  <ArrowRightIcon width="1.5rem" height="1.5rem" />
                </Link>
              </motion.li>
            )
          })}
        </LayoutGroup>
      </ul>
    </>
  )
}
