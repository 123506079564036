import React, { FunctionComponent, ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Common_Acceptance,
  Common_Cleaning,
  Common_UptimeRate,
  OverallStatus_SortBy,
  OverallStatus_Store
} from '../../../translations/messages'
import { Rate } from '../../shared'
import { ArrowRightIcon, ArrowUpIcon } from '../../../icons'
import { LocationType, SortOrderType, SortType, StoreSummaryType } from '../../../dataTypes'
import { useNavigate } from 'react-router'
import { intl } from '../../../lib/intl'
import { summarySort } from '../sort-helpers'
import { LayoutGroup, motion } from 'framer-motion'
import { useRouteLoaderData } from 'react-router-dom'

type Props = {
  storeSummaries: StoreSummaryType[]
}

type SortProps = {
  sortBy: SortType
  sortKey: SortType
  sortKeyReadable: string
  sortOrder: SortOrderType
  onSort: () => void
  children: ReactNode
}

const TableSort: FunctionComponent<SortProps> = ({ sortBy, sortKey, sortKeyReadable, sortOrder, onSort, children }) => {
  const isSortingKey = sortBy === sortKey

  return (
    <button onClick={onSort} aria-label={intl.formatMessage(OverallStatus_SortBy, { key: sortKeyReadable })}>
      <span className="flex">
        <span>{children}</span>

        {isSortingKey && (
          <span className="pl-sm pt-[0.15rem]">
            <ArrowUpIcon
              className={`transition ${sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
              width="1rem"
              height="1rem"
            />
          </span>
        )}
      </span>
    </button>
  )
}

export const StoresTable: FunctionComponent<Props> = ({ storeSummaries }) => {
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { acceptanceRateThreshold, uptimeRateThreshold, cleaningRateThreshold } = user?.settings || {}
  const navigate = useNavigate()
  const [sortBy, setSortBy] = useState<SortType>('locationName')
  const [sortOrder, setSortOrder] = useState<SortOrderType>('asc')

  const sortSummariesByKey = (key: SortType) => {
    if (key === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }

    setSortBy(key)
  }

  const redirectToStorePage = ({ region, locationId }: LocationType) => navigate(`/status/${region}/${locationId}`)

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="text-center">
            <TableSort
              sortBy={sortBy}
              sortKey="locationName"
              sortOrder={sortOrder}
              onSort={() => sortSummariesByKey('locationName')}
              sortKeyReadable={intl.formatMessage(OverallStatus_Store)}
            >
              <FormattedMessage id={OverallStatus_Store.id} defaultMessage={OverallStatus_Store.defaultMessage} />
            </TableSort>
          </th>
          <th className="text-center">
            <TableSort
              sortBy={sortBy}
              sortKey="acceptanceRate"
              sortOrder={sortOrder}
              onSort={() => sortSummariesByKey('acceptanceRate')}
              sortKeyReadable={intl.formatMessage(Common_Acceptance)}
            >
              <FormattedMessage id={Common_Acceptance.id} defaultMessage={Common_Acceptance.defaultMessage} />
            </TableSort>
          </th>
          <th className="text-center">
            <TableSort
              sortBy={sortBy}
              sortKey="uptimeRate"
              sortOrder={sortOrder}
              onSort={() => sortSummariesByKey('uptimeRate')}
              sortKeyReadable={intl.formatMessage(Common_UptimeRate)}
            >
              <FormattedMessage id={Common_UptimeRate.id} defaultMessage={Common_UptimeRate.defaultMessage} />
            </TableSort>
          </th>
          <th className="text-center">
            <TableSort
              sortBy={sortBy}
              sortKey="cleaningRate"
              sortOrder={sortOrder}
              onSort={() => sortSummariesByKey('cleaningRate')}
              sortKeyReadable={intl.formatMessage(Common_Cleaning)}
            >
              <FormattedMessage id={Common_Cleaning.id} defaultMessage={Common_Cleaning.defaultMessage} />
            </TableSort>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <LayoutGroup>
          {storeSummaries.sort(summarySort(sortBy, sortOrder)).map(storeSummary => (
            <motion.tr
              layout="position"
              className="hover:bg-grey-light cursor-pointer"
              onClick={() => redirectToStorePage(storeSummary.location)}
              key={storeSummary.location.locationName}
            >
              <td className="text-center">{storeSummary.location.locationName}</td>
              <td>
                <Rate value={storeSummary.acceptanceRate} threshold={acceptanceRateThreshold} />
              </td>
              <td>
                <Rate value={storeSummary.uptimeRate} threshold={uptimeRateThreshold} />
              </td>
              <td>
                <Rate value={storeSummary.cleaningRate} threshold={cleaningRateThreshold} />
              </td>
              <td>
                <div className="flex items-center justify-end">
                  <ArrowRightIcon width="1rem" height="1rem" />
                </div>
              </td>
            </motion.tr>
          ))}
        </LayoutGroup>
      </tbody>
    </table>
  )
}
