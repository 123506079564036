import { LocationType, RegionAdminType, UserAccessType } from '../../dataTypes'
import { EditIcon } from '../../icons'
import { locationSort } from '../../lib/util'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UserList_Title } from '../../translations/messages'
import { hasAdminRole } from '../../lib/authentication'
import { Link, useRouteLoaderData } from 'react-router-dom'

const userIsRegionAdmin = (regionAdmins: RegionAdminType[], user: UserAccessType) =>
  regionAdmins.some(regAdmin => regAdmin.userId === user.userId)

const userIsNotOfMyRegions = (user: UserAccessType, myRegions: string[]) =>
  user.locations.every(location => myRegions.includes(location.region))

type Props = {
  activeFilter: AdminPageFilter
  usersToDelete: UserAccessType[]
  toggleUserToDelete: (user: UserAccessType) => void
}

export const UserList: FunctionComponent<Props> = ({ activeFilter, usersToDelete, toggleUserToDelete }) => {
  const { myRegions } = useRouteLoaderData('root') as RootLoaderData
  const { userAccess, regionAdmins } = useRouteLoaderData('admin') as AdminPageLoaderType

  const [sortedUsers, setSortedUsers] = useState(userAccess || [])

  const restrictedUsers = userAccess.filter(
    user => !userIsNotOfMyRegions(user, myRegions) || userIsRegionAdmin(regionAdmins, user)
  )

  const userAccessFilter = useCallback(
    (userAccess: UserAccessType) => {
      const { textFilter, regionFilter } = activeFilter

      const email = userAccess.email.toLowerCase()
      const selectedRegions = userAccess.locations.map(location => location.region)
      const locations = userAccess.locations.map(location => `${location.region}-${location.locationId}`)

      const textFilterPass =
        textFilter === '' ||
        email.indexOf(textFilter.toLowerCase()) !== -1 ||
        locations.some(location => location.toLowerCase().startsWith(textFilter))

      const regionFilterPass =
        regionFilter.length === 0 ||
        (selectedRegions.length === 0 && regionFilter.includes('unassigned')) ||
        regionFilter.some(region => selectedRegions.includes(region))

      return textFilterPass && regionFilterPass
    },
    [activeFilter]
  )

  const userSort = useCallback((userA: UserAccessType, userB: UserAccessType) => {
    const [emailA, emailB] = [userA.email.toLowerCase(), userB.email.toLowerCase()]
    if (emailA > emailB) return 1
    if (emailA < emailB) return -1
    else return 0
  }, [])

  useEffect(() => {
    if (!userAccess) {
      return
    }

    setSortedUsers(userAccess.filter(userAccessFilter).sort(userSort))
  }, [userAccess, userAccessFilter, userSort])

  const locationsIncludedInActiveFilter = (location: LocationType) => {
    // For some reason the customer wants the FLEET_PORTAL_ADMIN to be able to
    // see all regions even though we have filtered by regions
    if (hasAdminRole()) {
      return true
    }

    const { regionFilter } = activeFilter

    const hasFilteredByRegion = regionFilter.length > 0
    const locationInRegion = regionFilter.includes(location.region)

    return !(hasFilteredByRegion && !locationInRegion)
  }

  return (
    <div className="mt-md -mx-md card pt-lg">
      <div className="mx-lg flex items-center space-x-sm mb-md">
        <h2 id="user-list-heading" className="text-md">
          <FormattedMessage id={UserList_Title.id} defaultMessage={UserList_Title.defaultMessage} />
        </h2>
        <span>
          ({sortedUsers.length}/{userAccess?.length})
        </span>
      </div>

      <ul aria-labelledby="user-list-heading">
        {sortedUsers.map(userAccess => {
          const { userId, email, locations } = userAccess

          const isRestrictedUser =
            !hasAdminRole() && restrictedUsers.some(restrictedUser => restrictedUser.userId === userId)

          return (
            <li
              className="py-md px-lg hover:bg-grey-light transition flex space-x-md items-start justify-between"
              key={userId}
            >
              <label id={`user-list-label-${userId}`} className="whitespace-nowrap w-20">
                <input
                  type="checkbox"
                  aria-labelledby={`user-list-label-${userId}`}
                  className="checkbox mr-md"
                  disabled={isRestrictedUser}
                  checked={usersToDelete.map(userToRemove => userToRemove.userId).includes(userId)}
                  onChange={() => toggleUserToDelete(userAccess)}
                />
                {email}
              </label>

              <span className="flex-1">
                <ul aria-label={`Locations for ${email}`} className="col-span-8 flex flex-wrap -m-xs">
                  {locations
                    .sort(locationSort)
                    .filter(locationsIncludedInActiveFilter)
                    .map(location => (
                      <li className="tag m-xs" key={JSON.stringify(location)}>
                        {`${location.region}-${location.locationId}`}
                      </li>
                    ))}
                </ul>
              </span>

              <Link to={`/admin/${userId}`} className="btn btn-icon" aria-label={`Edit user with email ${email}`}>
                <EditIcon aria-hidden="true" width="1.5rem" height="1.5rem" />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
