import React, { useState } from 'react'
import { Dialog } from '../shared'
import { UserAccessType } from '../../dataTypes'
import { useIntl } from 'react-intl'
import {
  Common_Cancel,
  Common_Close,
  DeleteUserDialog_AreYouSure,
  DeleteUserDialog_Delete,
  DeleteUserDialog_Failure,
  DeleteUserDialog_Success,
  DeleteUserDialog_Title,
  DeleteUserDialog_UserList
} from '../../translations/messages'
import { deleteUsers } from '../../services/access-service'

type PropType = {
  usersToDelete: UserAccessType[]
  onClose: () => void
}

export const DeleteUserDialog = ({ usersToDelete, onClose }: PropType) => {
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'success' | 'failure'>('idle')
  const intl = useIntl()

  const onDeleteUsers = async (event: any) => {
    try {
      event.preventDefault()
      setRequestStatus('pending')

      await deleteUsers(usersToDelete.map(u => u.userId))

      setRequestStatus('success')
    } catch (error: unknown) {
      setRequestStatus('failure')
    }
  }

  const deletedUsers = (
    <ul className="mt-md">
      {usersToDelete.map(user => (
        <li key={user.userId} className="pb-xs font-bold">
          {user.email}
        </li>
      ))}
    </ul>
  )

  return (
    <Dialog labeledBy="deleteUserTitle" describedBy="deleteUserDescription">
      <div className="card p-lg">
        <h1 id="deleteUserTitle" className="text-xl mb-md">
          {intl.formatMessage(DeleteUserDialog_Title)}
        </h1>

        {requestStatus === 'success' ? (
          <div>
            <p>{intl.formatMessage(DeleteUserDialog_Success)}</p>

            {deletedUsers}

            <div className="flex justify-end mt-md">
              <button className="btn" onClick={onClose}>
                {intl.formatMessage(Common_Close)}
              </button>
            </div>
          </div>
        ) : requestStatus === 'failure' ? (
          <div>
            <p>{intl.formatMessage(DeleteUserDialog_Failure)}</p>

            <div className="flex justify-end mt-md">
              <button className="btn" onClick={onClose}>
                {intl.formatMessage(Common_Close)}
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={onDeleteUsers}>
            <div id="deleteUserDescription">
              <span>{intl.formatMessage(DeleteUserDialog_UserList)}</span>
              {deletedUsers}
            </div>
            <div className="mt-md">{intl.formatMessage(DeleteUserDialog_AreYouSure)}</div>
            <div className="mt-md">
              {requestStatus === 'pending' ? (
                <div className="loadingSpinner m-auto" />
              ) : (
                <div className="flex justify-end space-x-md">
                  <input type="hidden" name="userIds" value={usersToDelete.map(u => u.userId)} />

                  <button className="btn" onClick={onClose}>
                    {intl.formatMessage(Common_Cancel)}
                  </button>

                  <button type="submit" className="btn btn-primary-danger">
                    {intl.formatMessage(DeleteUserDialog_Delete)}
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </Dialog>
  )
}
