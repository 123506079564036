import React, { useRef, useState } from 'react'
import { Dialog } from '../shared'
import { useIntl } from 'react-intl'
import {
  AddUserDialog_AddAnotherUser,
  AddUserDialog_ConflictMessage,
  AddUserDialog_Email,
  AddUserDialog_FailureMessage,
  AddUserDialog_SuccessfullyCreatedUser_Description,
  AddUserDialog_SuccessfullyCreatedUser_Title,
  AddUserDialog_TemporaryPassword,
  AdminPage_AddNewUser,
  Common_Cancel,
  Common_Close,
  Common_Submit
} from '../../translations/messages'
import { postNewUser } from '../../services/access-service'
import { HttpError } from '@tomra/client-side-http-client'

export const AddUserDialog = ({ onClose }: { onClose: () => void }) => {
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'success' | 'conflict' | 'failure'>('idle')
  const [email, setEmail] = useState('')
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserPassword, setNewUserPassword] = useState('')
  const intl = useIntl()
  const formRef = useRef<HTMLFormElement>(null)

  const createNewUser = async (event: any) => {
    event.preventDefault()
    try {
      setRequestStatus('pending')

      const data = await postNewUser(email)
      setNewUserPassword(data.tempPassword)
      setNewUserEmail(email)
      setEmail('')
      formRef?.current?.reset()

      setRequestStatus('success')
    } catch (error: unknown) {
      if ((error as HttpError).status === 409) {
        setRequestStatus('conflict')
      } else {
        setRequestStatus('failure')
      }
    }
  }

  return (
    <Dialog labeledBy="addUserTitle" describedBy="addUserDescription">
      <div className="card p-lg">
        <h1 id="addUserTitle" className="text-xl mb-md">
          {intl.formatMessage(AdminPage_AddNewUser)}
        </h1>

        {requestStatus === 'success' && (
          <div className="flex flex-col gap-md">
            <span>{intl.formatMessage(AddUserDialog_SuccessfullyCreatedUser_Title, { email: newUserEmail })}</span>

            <div className="font-bold">
              {intl.formatMessage(AddUserDialog_TemporaryPassword, { password: newUserPassword })}
            </div>
            <div>{intl.formatMessage(AddUserDialog_SuccessfullyCreatedUser_Description)}</div>

            <h2 className="text-md mt-lg mb-sm">{intl.formatMessage(AddUserDialog_AddAnotherUser)}</h2>
          </div>
        )}

        <form id="addUserDescription" ref={formRef} onSubmit={createNewUser}>
          <label htmlFor="email" className="label">
            {intl.formatMessage(AddUserDialog_Email)}
          </label>
          <input id="email" type="email" name="email" value={email} onChange={({ target }) => setEmail(target.value)} />

          <div className="flex justify-end mt-md">
            {requestStatus === 'pending' ? (
              <div className="loadingSpinner m-auto" />
            ) : (
              <div className="flex space-x-md">
                <button type="button" className="btn" onClick={onClose}>
                  {requestStatus === 'success' ? intl.formatMessage(Common_Close) : intl.formatMessage(Common_Cancel)}
                </button>

                <button type="submit" className="btn btn-primary-dark">
                  {intl.formatMessage(Common_Submit)}
                </button>
              </div>
            )}
          </div>

          {requestStatus === 'failure' ? (
            <div className="font-bold text-red-dark mt-md">{intl.formatMessage(AddUserDialog_FailureMessage)}</div>
          ) : requestStatus === 'conflict' ? (
            <div className="font-bold text-red-dark mt-md">{intl.formatMessage(AddUserDialog_ConflictMessage)}</div>
          ) : null}
        </form>
      </div>
    </Dialog>
  )
}
