import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Common_Acceptance,
  Common_AcceptedVolume,
  Common_AvgDowntime,
  Common_AvgUptime,
  Common_Cleaning,
  Common_Cleanings,
  Common_UptimeRate,
  OverallStatus_AvgTime,
  OverallStatus_LastFourWeeks,
  OverallStatus_MyStores,
  OverallStatus_Title
} from '../../../translations/messages'
import { Rate } from '../../shared'
import { secondsToHoursAndMinutes, secondsToMinutesAndSeconds } from '../../../lib/util'
import { StoresTable } from './StoresTable'
import { OverallStatusType } from '../../../dataTypes'
import { useRouteLoaderData } from 'react-router-dom'
import { ExportCSV } from '../ExportCSV'
import { motion } from 'framer-motion'

const textContainerStyles = `text-center font-bold text-sm`

type Props = {
  status: OverallStatusType
}

export const OverallStatusDesktop: FunctionComponent<Props> = ({ status }) => {
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { acceptanceRateThreshold, uptimeRateThreshold, cleaningRateThreshold } = user?.settings || {}

  return (
    <div className="max-w-screen-sm mx-auto">
      <div className="card mt-md">
        <div className="p-lg">
          <div className="flex space-x-md items-start justify-between">
            <div>
              <h1 id={OverallStatus_Title.id} className="text-lg">
                <FormattedMessage id={OverallStatus_Title.id} defaultMessage={OverallStatus_Title.defaultMessage} />
              </h1>
              <p className="font-bold text-sm">
                <FormattedMessage
                  id={OverallStatus_LastFourWeeks.id}
                  defaultMessage={OverallStatus_LastFourWeeks.defaultMessage}
                />
              </p>
            </div>

            <ExportCSV />
          </div>
        </div>

        <motion.div
          layout="position"
          className="grid grid-cols-3 gap-lg mb-lg"
          role="region"
          aria-labelledby={OverallStatus_Title.id}
        >
          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_Acceptance.id} defaultMessage={Common_Acceptance.defaultMessage} />
            </dt>
            <dd className="centerFlex text-lg col-span-1 row-start-2 col-start-1">
              <Rate value={status.acceptanceRate} threshold={acceptanceRateThreshold} />
            </dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_UptimeRate.id} defaultMessage={Common_UptimeRate.defaultMessage} />
            </dt>
            <dd className="centerFlex text-lg">
              <Rate value={status.uptimeRate} threshold={uptimeRateThreshold} />
            </dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_Cleaning.id} defaultMessage={Common_Cleaning.defaultMessage} />
            </dt>
            <dd className="centerFlex text-lg">
              <Rate value={status.cleaningRate} threshold={cleaningRateThreshold} />
            </dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_AcceptedVolume.id} defaultMessage={Common_AcceptedVolume.defaultMessage} />
            </dt>
            <dd className="centerFlex">{status.acceptedVolume.toLocaleString('no')}</dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_AvgUptime.id} defaultMessage={Common_AvgUptime.defaultMessage} />
            </dt>
            <dd className="centerFlex">{secondsToHoursAndMinutes(status.averageUptimeInSeconds)}</dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_Cleanings.id} defaultMessage={Common_Cleanings.defaultMessage} />
            </dt>
            <dd className="centerFlex">
              {status.cleaningCount}/{status.expectedCleaningCount}
            </dd>
          </dl>

          <dl className="col-start-2">
            <dt className={textContainerStyles}>
              <FormattedMessage id={Common_AvgDowntime.id} defaultMessage={Common_AvgDowntime.defaultMessage} />
            </dt>
            <dd className="centerFlex">{secondsToHoursAndMinutes(status.averageDowntimeInSeconds)}</dd>
          </dl>

          <dl>
            <dt className={textContainerStyles}>
              <FormattedMessage id={OverallStatus_AvgTime.id} defaultMessage={OverallStatus_AvgTime.defaultMessage} />
            </dt>
            <dd className="centerFlex">{secondsToMinutesAndSeconds(status.averageCleaningDurationInSeconds)}</dd>
          </dl>
        </motion.div>

        <h2 className="mx-lg mb-sm text-lg">
          <FormattedMessage id={OverallStatus_MyStores.id} defaultMessage={OverallStatus_MyStores.defaultMessage} />
        </h2>
        <StoresTable storeSummaries={status.storeSummaries} />
      </div>
    </div>
  )
}
