import { fetchData } from '@tomra/client-side-http-client'
import { authStore } from './authentication'

export const authenticatedFetchData = (url: string, options: RequestInit = {}) => {
  return fetchData(
    url,
    { ...options },
    {
      fetchNewToken: authStore.fetchNewToken,
      getToken: authStore.getToken,
      retryCount: 0,
      timeoutInMs: 5 * 60000 // some long running tasks in fleet portal api
    }
  ).run()
}
