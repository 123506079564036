import React from 'react'
import { RawIntlProvider } from 'react-intl'
import { intl } from './lib/intl'
import { authStore } from './lib/authentication'
import { App } from './components/App'
import '@tomra/design-system/src/config/tailwind.config.css'
import { createRoot } from 'react-dom/client'
import './index.css'
import { initAmplitude } from './lib/amplitudeUtils'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

authStore
  .init()
  .then(() => {
    initAmplitude()
    root.render(
      <React.StrictMode>
        <RawIntlProvider value={intl}>
          <App />
        </RawIntlProvider>
      </React.StrictMode>
    )
  })
  .catch(() => {
    authStore.login()
  })
