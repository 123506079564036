import React from 'react'
import { hasAdminRole } from '../../../lib/authentication'
import { FormattedMessage } from 'react-intl'
import { UserAccessPage_RegionsToAdminister } from '../../../translations/messages'
import { useFetcher, useParams, useRouteLoaderData } from 'react-router-dom'
import { ActionFunction } from 'react-router'
import { addRegionAdmin, removeRegionAdmin } from '../../../services/access-service'
import { motion } from 'framer-motion'

export const toggleRegionAdminAction: ActionFunction = async ({ request, params }) => {
  const { userId } = params as { userId: string }
  const { region } = Object.fromEntries(await request.formData()) as { region: string }

  if (request.method === 'PUT') {
    return await addRegionAdmin(userId, region)
  }

  if (request.method === 'DELETE') {
    return await removeRegionAdmin(userId, region)
  }

  return null
}

export const RegionAdministration = () => {
  const { userId } = useParams() as { userId: string }
  const { regionAdmins, locationsGroupedByRegion } = useRouteLoaderData('admin') as AdminPageLoaderType

  const fetcher = useFetcher()

  const administratedRegions = regionAdmins?.find(regionAdmin => regionAdmin.userId === userId)?.regions

  const adminRegionBeingUpdated = ''

  const toggleAdministerRegion = (region: string) => {
    const userIsAdministratingRegion = administratedRegions?.some(r => r === region)

    if (userIsAdministratingRegion) {
      return fetcher.submit({ region }, { method: 'delete', action: `/admin/${userId}/toggle-admin-region` })
    }

    return fetcher.submit({ region }, { method: 'put', action: `/admin/${userId}/toggle-admin-region` })
  }

  return (
    hasAdminRole() && (
      <motion.div layout="position" className="card p-lg">
        <h2 className="text-lg mb-md">
          <FormattedMessage
            id={UserAccessPage_RegionsToAdminister.id}
            defaultMessage={UserAccessPage_RegionsToAdminister.defaultMessage}
          />
        </h2>

        <ul className="grid grid-cols-8 gap-md">
          {Object.keys(locationsGroupedByRegion).map(region => (
            <li key={region}>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-sm"
                  aria-label={`toggle admin access to region ${region}`}
                  onChange={() => toggleAdministerRegion(region)}
                  checked={administratedRegions?.includes(region)}
                  disabled={adminRegionBeingUpdated === region}
                />
                {region}
              </label>
            </li>
          ))}
        </ul>
      </motion.div>
    )
  )
}
