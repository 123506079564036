import React, { useState } from 'react'
import { AlertIcon } from '../icons'
import { FormattedMessage } from 'react-intl'
import {
  Common_Cancel,
  TermsAndConditions_Checkbox_Label,
  TermsAndConditions_Continue,
  TermsAndConditions_Description_Link,
  TermsAndConditions_Description_Text_One,
  TermsAndConditions_Description_Text_Two,
  TermsAndConditions_Highlights_Item_One,
  TermsAndConditions_Highlights_Item_Three,
  TermsAndConditions_Highlights_Item_Two,
  TermsAndConditions_Highlights_Title,
  TermsAndConditions_Title
} from '../translations/messages'
import { authStore } from '../lib/authentication'

export const TermsAndConditions = ({ acceptTermsAndConditions }: { acceptTermsAndConditions: () => void }) => {
  const [consentChecked, setConsentChecked] = useState(false)

  return (
    <div className="card p-lg max-w-screen-sm mx-auto">
      <div className="flex justify-center mb-md">
        <AlertIcon color="orange" width="3rem" height="3rem" />
      </div>

      <h1 className="text-xl mb-sm">
        <FormattedMessage id={TermsAndConditions_Title.id} defaultMessage={TermsAndConditions_Title.defaultMessage} />
      </h1>

      <div className="prose">
        <p>
          <span>
            <FormattedMessage
              id={TermsAndConditions_Description_Text_One.id}
              defaultMessage={TermsAndConditions_Description_Text_One.defaultMessage}
            />
          </span>
          <br />
          <span>
            <FormattedMessage
              id={TermsAndConditions_Description_Text_Two.id}
              defaultMessage={TermsAndConditions_Description_Text_Two.defaultMessage}
            />
          </span>
          <a
            href="/fleet_portal_terms_and_conditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
          >
            <FormattedMessage
              id={TermsAndConditions_Description_Link.id}
              defaultMessage={TermsAndConditions_Description_Link.defaultMessage}
            />
          </a>
        </p>
      </div>

      <h2 className="text-lg mt-md">
        <FormattedMessage
          id={TermsAndConditions_Highlights_Title.id}
          defaultMessage={TermsAndConditions_Highlights_Title.defaultMessage}
        />
      </h2>
      <div className="prose">
        <ol>
          <li>
            <FormattedMessage
              id={TermsAndConditions_Highlights_Item_One.id}
              defaultMessage={TermsAndConditions_Highlights_Item_One.defaultMessage}
            />
          </li>
          <li>
            <FormattedMessage
              id={TermsAndConditions_Highlights_Item_Two.id}
              defaultMessage={TermsAndConditions_Highlights_Item_Two.defaultMessage}
            />
          </li>
          <li>
            <FormattedMessage
              id={TermsAndConditions_Highlights_Item_Three.id}
              defaultMessage={TermsAndConditions_Highlights_Item_Three.defaultMessage}
            />
          </li>
        </ol>
      </div>

      <label className="mt-md inline-block">
        <input
          type="checkbox"
          className="checkbox mr-sm"
          defaultChecked={consentChecked}
          onChange={e => setConsentChecked(e.target.checked)}
        />
        <FormattedMessage
          id={TermsAndConditions_Checkbox_Label.id}
          defaultMessage={TermsAndConditions_Checkbox_Label.defaultMessage}
        />
      </label>

      <div className="flex mt-md justify-end space-x-md">
        <button className="btn" onClick={authStore.logout}>
          <FormattedMessage id={Common_Cancel.id} defaultMessage={Common_Cancel.defaultMessage} />
        </button>
        <button className="btn btn-primary-dark" disabled={!consentChecked} onClick={acceptTermsAndConditions}>
          <FormattedMessage
            id={TermsAndConditions_Continue.id}
            defaultMessage={TermsAndConditions_Continue.defaultMessage}
          />
        </button>
      </div>
    </div>
  )
}
