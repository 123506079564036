import React from 'react'
import { LanguageSelect_English, LanguageSelect_German } from '../../translations/messages'
import { intl } from '../../lib/intl'

export const LanguageSelect = () => {
  const languages: { [key: string]: { id: string; defaultMessage: string } } = {
    en: LanguageSelect_English,
    de: LanguageSelect_German
  }

  const setActiveLanguage = (langCode: string) => {
    window.localStorage.setItem('activeLanguage', langCode)
    window.location.reload()
  }

  return (
    <select
      id="language-select"
      className="select"
      value={intl.locale}
      onChange={e => setActiveLanguage(e.target.value)}
    >
      {Object.keys(languages).map(langCode => {
        return (
          <option key={langCode} value={langCode}>
            {intl.formatMessage(languages[langCode])}
          </option>
        )
      })}
    </select>
  )
}
