import React from 'react'
import { isRouteErrorResponse, Outlet, RouterProvider, useRouteError } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import { AppLayout, userDataLoader } from './AppLayout'
import { logError } from '../lib/logError'
import { overallStatusLoader, OverallStatusPage } from './overall-status'
import { AdminPage } from './admin-page'
import { settingsAction, SettingsPage } from './SettingsPage'
import { adminDataLoader } from './admin-page/AdminPage'
import { PageErrorElement } from './shared/PageErrorElement'
import { UserAccessPage, toggleUserAccessLocationAction, toggleUserAccessRegionAction } from './user-access'
import { storeStatusLoader, StoreStatusPage } from './store-status/StoreStatus'
import { PageNotFound } from './PageNotFound'
import { toggleRegionAdminAction } from './user-access/region-administration/RegionAdministration'
import { uploadStatusLoader, UploadStatusPage } from './upload-status/UploadStatusPage'

const RootErrorElement = () => {
  const maybeRouteError: any = useRouteError()

  if (maybeRouteError) {
    logError(new Error('Error caught in ErrorElement'), maybeRouteError)
  }

  const error = isRouteErrorResponse(maybeRouteError) ? 'Something went wrong' : maybeRouteError
  const errorMessage = error.body?.detail || error.body?.title

  return <div className="font-bold text-red">{errorMessage || 'Something went wrong'}</div>
}

const appRoutes = [
  {
    id: 'root',
    loader: userDataLoader,
    element: <AppLayout />,
    errorElement: <RootErrorElement />,
    children: [
      {
        index: true,
        loader: overallStatusLoader,
        element: <OverallStatusPage />,
        errorElement: <PageErrorElement />
      },
      {
        path: 'status/:region/:locationId',
        loader: storeStatusLoader,
        element: <StoreStatusPage />,
        errorElement: <PageErrorElement />
      },
      {
        id: 'admin',
        path: '/admin',
        loader: adminDataLoader,
        element: <Outlet />,
        errorElement: <PageErrorElement />,
        children: [
          {
            index: true,
            element: <AdminPage />
          },
          {
            path: ':userId',
            element: <UserAccessPage />,
            errorElement: <PageErrorElement />,
            children: [
              {
                path: 'user-access-location',
                action: toggleUserAccessLocationAction
              },
              {
                path: 'user-access-region',
                action: toggleUserAccessRegionAction
              },
              {
                path: 'toggle-admin-region',
                action: toggleRegionAdminAction
              },
              {
                path: 'new-password'
              }
            ]
          }
        ]
      },
      {
        path: '/settings',
        element: <SettingsPage />,
        action: settingsAction,
        errorElement: <PageErrorElement />
      },
      {
        path: '/upload-status',
        element: <UploadStatusPage />,
        loader: uploadStatusLoader,
        errorElement: <PageErrorElement />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
]

export const App = () => (
  <RouterProvider
    router={createBrowserRouter(appRoutes)}
    fallbackElement={
      <div className="centerFlex mt-lg">
        <div className="loadingSpinner" />
      </div>
    }
  />
)
