import React, { useEffect, useState } from 'react'
import TomraArrow from '../../images/tomra-arrow.png'
import { CloseIcon, MenuIcon, SettingsIcon } from '../../icons'
import { Link, useRouteLoaderData, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Common_Menu, OverallStatus_Title, Settings_Admin, Settings_Title } from '../../translations/messages'
import { getEmailFromToken, hasAdminRole } from '../../lib/authentication'
import { useMediaQuery } from 'react-responsive'
import { AnimatePresence, motion } from 'framer-motion'

export const Header = () => {
  const intl = useIntl()
  const { myRegions } = useRouteLoaderData('root') as RootLoaderData
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' })
  const [showMenu, setShowMenu] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setShowMenu(false)
  }, [pathname])

  const settingsLink = (
    <Link to="/settings" className="btn btn-icon flex items-center">
      <SettingsIcon title={intl.formatMessage(Settings_Title)} color="white" width="1.5rem" height="1.5rem" />
    </Link>
  )

  const isAdmin = hasAdminRole()

  return (
    <nav className="navbar">
      <div className="max-w-screen-2xl mx-auto w-full flex items-center justify-between">
        <Link to="/">
          <div className="flex items-center">
            <img src={TomraArrow} className="h-xl mr-md" alt="TOMRA logo" />
            <span className="truncateText">{getEmailFromToken()}</span>
          </div>
        </Link>

        {isDesktopOrLaptop ? (
          <>
            <div className="flex items-center">
              {(isAdmin || myRegions.length > 0) && (
                <Link to="/admin" className="btn">
                  <FormattedMessage id={Settings_Admin.id} defaultMessage={Settings_Admin.defaultMessage} />
                </Link>
              )}

              {settingsLink}
            </div>
          </>
        ) : !isAdmin ? (
          settingsLink
        ) : (
          <>
            <button
              className="btn btn-icon"
              aria-label={intl.formatMessage(Common_Menu)}
              onClick={() => setShowMenu(prev => !prev)}
            >
              <MenuIcon width="1.5rem" height="1.5rem" />
            </button>

            <AnimatePresence initial={false}>
              {showMenu && (
                <motion.ul
                  className="fixed card p-md w-3/4 h-full top-0 right-0 z-10 flex flex-col items-end space-y-md"
                  initial={{ opacity: 0, translateX: '100%' }}
                  animate={{ opacity: 1, translateX: '0%' }}
                  exit={{ opacity: 0, translateX: '100%' }}
                >
                  <li>
                    <button
                      className="btn btn-icon"
                      aria-label={intl.formatMessage(Common_Menu)}
                      onClick={() => setShowMenu(prev => !prev)}
                    >
                      <CloseIcon color="var(--colors-black)" width="1.5rem" height="1.5rem" />
                    </button>
                  </li>
                  <li className="w-full">
                    <Link
                      to="/"
                      className={`block font-bold text-black rounded-half px-md py-sm ${
                        pathname === '/' ? 'bg-fog' : 'bg-white'
                      }`}
                    >
                      <FormattedMessage
                        id={OverallStatus_Title.id}
                        defaultMessage={OverallStatus_Title.defaultMessage}
                      />
                    </Link>
                  </li>
                  <li className="w-full">
                    <Link
                      to="/admin"
                      className={`block font-bold text-black rounded-half px-md py-sm ${
                        pathname === '/admin' ? 'bg-fog' : 'bg-white'
                      }`}
                    >
                      <FormattedMessage id={Settings_Admin.id} defaultMessage={Settings_Admin.defaultMessage} />
                    </Link>
                  </li>
                  <li className="w-full">
                    <Link
                      to="/settings"
                      className={`block font-bold text-black rounded-half px-md py-sm ${
                        pathname === '/settings' ? 'bg-fog' : 'bg-white'
                      }`}
                    >
                      {intl.formatMessage(Settings_Title)}
                    </Link>
                  </li>
                </motion.ul>
              )}
            </AnimatePresence>
          </>
        )}
      </div>
    </nav>
  )
}
