import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import localforage from 'localforage'
import jwtDecode from 'jwt-decode'
import { KEYCLOAK_HOST } from './environment'

export const storage: WebStorage = {
  setItem: localforage.setItem as (key: string, value: string) => Promise<void>,
  getItem: localforage.getItem as (key: string) => Promise<string>,
  removeItem: localforage.removeItem,
  clear: localforage.clear
}

export const authStore = new AuthStore('TomraFleetPortalUsers', 'fleet-portal-ui', KEYCLOAK_HOST, 'en', storage)

export const getDecodedToken = () => {
  if (authStore) {
    try {
      const token = authStore.getToken()
      return jwtDecode(token) as any
    } catch {
      return null
    }
  }

  return null
}

export const getEmailFromToken = () => {
  const decodedToken = getDecodedToken()
  return decodedToken.email
}

export const hasAdminRole: () => Boolean = () => {
  const decodedToken = getDecodedToken()
  return decodedToken?.full_resource_access?.includes('FLEET_PORTAL_ADMIN')
}
