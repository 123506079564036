import React, { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { RegionAdminType } from '../../dataTypes'
import { FormattedMessage } from 'react-intl'
import { RegionAdminList_Title } from '../../translations/messages'
import { MinusIcon, PlusIcon } from '../../icons'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouteLoaderData } from 'react-router-dom'

type Props = {
  activeFilter: AdminPageFilter
}

export const RegionAdminList: FunctionComponent<Props> = ({ activeFilter }) => {
  const { regionAdmins } = useRouteLoaderData('admin') as AdminPageLoaderType
  const [adminListExpanded, setAdminListExpanded] = useState(false)

  const regionAdminFilter = useCallback(
    (regionAdmin: RegionAdminType) => {
      const { textFilter, regionFilter } = activeFilter

      const email = regionAdmin.email.toLowerCase()

      const textFilterPass = textFilter === '' || email.indexOf(textFilter.toLowerCase()) !== -1

      const regionFilterPass =
        regionFilter.length === 0 || regionFilter.some(region => regionAdmin.regions.includes(region))

      return textFilterPass && regionFilterPass
    },
    [activeFilter]
  )

  const regionAdminSort = (regionAdminA: RegionAdminType, regionAdminB: RegionAdminType) => {
    const [emailA, emailB] = [regionAdminA.email.toLowerCase(), regionAdminB.email.toLowerCase()]

    if (emailA > emailB) return 1

    if (emailA < emailB) return -1
    else return 0
  }

  const sortedRegionAdmins = useMemo(() => {
    if (!regionAdmins) {
      return []
    }

    return regionAdmins.filter(regionAdminFilter).sort(regionAdminSort)
  }, [regionAdminFilter, regionAdmins])

  return (
    <div className="card -mx-md mt-md">
      <h2 id="region-admin-list-heading">
        <button
          aria-controls="region-admin-list"
          aria-expanded={adminListExpanded}
          className="px-lg py-md w-full"
          onClick={() => setAdminListExpanded(!adminListExpanded)}
        >
          <span className="flex items-center justify-between">
            <span className="flex items-center space-x-sm">
              <span className="text-md">
                <FormattedMessage id={RegionAdminList_Title.id} defaultMessage={RegionAdminList_Title.defaultMessage} />
              </span>
              <span>
                ({sortedRegionAdmins.length}/{regionAdmins.length})
              </span>
            </span>

            {adminListExpanded ? (
              <MinusIcon aria-hidden="true" width="1.5rem" height="1.5rem" />
            ) : (
              <PlusIcon aria-hidden="true" width="1.5rem" height="1.5rem" />
            )}
          </span>
        </button>
      </h2>
      <motion.ul
        id="region-admin-list"
        className="flex flex-col"
        aria-labelledby="region-admin-list-heading"
        aria-hidden={!adminListExpanded}
        initial={false}
        animate={adminListExpanded ? { height: 'auto' } : { height: '0', transition: { delay: 0.2 } }}
      >
        <AnimatePresence initial={false}>
          {adminListExpanded
            ? sortedRegionAdmins.map(regionAdmin => (
                <motion.li
                  key={regionAdmin.userId}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="py-md px-lg grid grid-cols-12 hover:bg-grey-light transition"
                >
                  <span className="col-span-4 whitespace-nowrap">{regionAdmin.email}</span>
                  <span className="col-span-8 flex flex-wrap -m-xs">
                    {regionAdmin.regions.sort().map(region => (
                      <span key={region} className="tag m-xs text-center">
                        {region}
                      </span>
                    ))}
                  </span>
                </motion.li>
              ))
            : null}
        </AnimatePresence>
      </motion.ul>
    </div>
  )
}
