import React, { useEffect, useRef, useState } from 'react'
import { PlusIcon, MinusIcon } from '../../../icons'
import { LocationType, UserAccessType } from '../../../dataTypes'
import { AnimatePresence, motion } from 'framer-motion'
import { useFetcher, useParams } from 'react-router-dom'

type Props = {
  region: string
  userAccess: UserAccessType
  locationsInRegion: LocationType[]
  disabled: boolean
}

const noOp = () => {}

export const RegionAccordion = ({ region, userAccess, locationsInRegion, disabled = false }: Props) => {
  const { userId } = useParams() as { userId: string }
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const fetcher = useFetcher()

  const selectedLocations = userAccess.locations

  useEffect(() => {
    const selectedLocationsInRegion = userAccess.locations.filter(location => location.region === region)
    const allRegionsSelected = selectedLocationsInRegion.length === locationsInRegion.length
    const checkboxRendered = ref && ref.current

    const hasCheckedSomeLocations = selectedLocationsInRegion.length > 0 && !allRegionsSelected

    if (checkboxRendered) {
      ref.current.indeterminate = hasCheckedSomeLocations
    }
  }, [locationsInRegion, ref, region, userAccess.locations])

  const locationsEqual = (locationA: LocationType, locationB: LocationType) =>
    locationA.region === locationB.region && locationA.locationId === locationB.locationId

  const entireRegionToggled = !locationsInRegion.some(
    location => !selectedLocations.some(selectedLocation => locationsEqual(location, selectedLocation))
  )

  const toggleRegion = (region: string) => {
    return fetcher.submit({ region }, { action: `/admin/${userId}/user-access-region`, method: 'put' })
  }

  const toggleLocation = (location: LocationType) => {
    const userHasAccess = selectedLocations.some(selectedLocation => locationsEqual(selectedLocation, location))

    if (userHasAccess) {
      return fetcher.submit(
        { region: location.region, locationId: location.locationId },
        { method: 'delete', action: `/admin/${userId}/user-access-location` }
      )
    }

    return fetcher.submit(
      { region: location.region, locationId: location.locationId },
      { method: 'put', action: `/admin/${userId}/user-access-location` }
    )
  }

  const isPending = fetcher.state === 'submitting' || fetcher.state === 'loading'
  const isTogglingRegion = isPending && fetcher.formData?.get('region') === region

  return (
    <>
      <div className="flex items-center">
        <input
          ref={ref}
          type="checkbox"
          className="mr-sm"
          aria-label={`toggle access to region ${region}`}
          disabled={disabled || isTogglingRegion}
          checked={entireRegionToggled}
          onChange={() => toggleRegion(region)}
        />

        <button
          aria-label={`expand ${region} accordion`}
          aria-expanded={expanded}
          aria-disabled={disabled}
          onClick={() => (disabled ? noOp : setExpanded(!expanded))}
        >
          <span className="flex items-center space-x-xs">
            <span>{region}</span>
            {expanded ? (
              <MinusIcon width="1rem" height="1rem" />
            ) : (
              <PlusIcon color={disabled ? 'grey' : 'black'} width="1rem" height="1rem" />
            )}
          </span>
        </button>

        {isTogglingRegion && <div className="loadingSpinner ml-sm" style={{ fontSize: '0.7rem' }} />}
      </div>

      <AnimatePresence>
        {expanded && (
          <motion.ul
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="flex flex-col space-y-sm mt-sm ml-lg"
            aria-hidden={!expanded}
          >
            {locationsInRegion.map(location => {
              const isTogglingLocation =
                fetcher.formData?.get('region') === location.region &&
                fetcher.formData?.get('locationId') === location.locationId

              return (
                <motion.li key={location.locationId}>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-sm"
                      name="location"
                      aria-label={`toggle access to location ${location.region}-${location.locationId}`}
                      disabled={isTogglingRegion || isTogglingLocation}
                      checked={selectedLocations.some(selectedLocation => locationsEqual(selectedLocation, location))}
                      onChange={() => toggleLocation(location)}
                    />
                    <span>{`${location.region} - ${location.locationId}`}</span>
                    {isTogglingLocation && (
                      <span>
                        <div className="loadingSpinner ml-sm" style={{ fontSize: '0.7rem' }} />
                      </span>
                    )}
                  </label>
                </motion.li>
              )
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    </>
  )
}
