import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, FormattedRelativeTime } from 'react-intl'
import RvmIconTomra from '../../images/rvm-icon-tomra.svg'
import RvmIconGeneral from '../../images/rvm-icon-general.svg'
import { RvmDetailType } from '../../dataTypes'
import { StoreStatus_LastUpdatedDate } from '../../translations/messages'

const Container = styled.div`
  :last-child {
    margin-left: var(--spacing-sm);
  }

  > span {
    display: none;
  }

  :hover > span {
    display: block;
    background-color: var(--colors-atlantic);
    border-radius: var(--radius-small);
    padding: var(--spacing-md);
    box-shadow: var(--shadow-lg);
    color: black;
    font-size: var(--font-size-sm);
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 100;
  }
`
type Props = {
  rvmDetail: RvmDetailType
}

export const RvmTooltip = ({ rvmDetail }: Props) => {
  const startOfCurrentDay = new Date().setUTCHours(0, 0, 0)
  const secondsSinceUpdatedTime =
    rvmDetail.mostRecentStats && (Date.parse(rvmDetail.mostRecentStats) - startOfCurrentDay) / 1000

  return (
    <Container className="relative inline-block m-0" onClick={evt => evt.stopPropagation()}>
      <img src={rvmDetail?.source === 'TOMRA' ? RvmIconTomra : RvmIconGeneral} className="h-xl" alt="rvm" />
      <span>
        {`${rvmDetail.rvmSerial} `}
        <FormattedMessage
          id={StoreStatus_LastUpdatedDate.id}
          defaultMessage={StoreStatus_LastUpdatedDate.defaultMessage}
        />
        {secondsSinceUpdatedTime && (
          <FormattedRelativeTime value={secondsSinceUpdatedTime} updateIntervalInSeconds={1} numeric="auto" />
        )}
      </span>
    </Container>
  )
}
