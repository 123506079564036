import { fetchData } from '@tomra/client-side-http-client'
import { API_HOST } from '../lib/environment'
import { authStore } from '../lib/authentication'
import { authenticatedFetchData } from '../lib/fetch'

export const fetchUserAccessList = () => {
  return fetchData(
    `${API_HOST}/access/v1/users`,
    {},
    {
      fetchNewToken: authStore.fetchNewToken,
      getToken: authStore.getToken,
      retryCount: 0
    }
  )
}

export const postNewUser = (email: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users`, {
    method: 'POST',
    body: JSON.stringify({ email })
  })

export const generateNewPassword = (userId: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users/${userId}/new-password`, {
    method: 'PUT'
  })

export const deleteUsers = (userIds: string[]) => {
  return Promise.all(userIds.map(deleteUserAccess))
}

export const deleteUserAccess = (userId: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users/${userId}`, {
    method: 'DELETE'
  })

export const fetchLocations = () => authenticatedFetchData(`${API_HOST}/access/v1/locations`, {})

export const addLocation = (userId: string, region: string, locationId: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users/${userId}/${region}/${locationId}`, {
    method: 'PUT'
  })

export const removeLocation = (userId: string, region: string, locationId: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users/${userId}/${region}/${locationId}`, {
    method: 'DELETE'
  })

export const toggleAccessForRegion = (userId: string, region: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/users/${userId}/${region}`, {
    method: 'PUT'
  })

export const fetchRegionAdmins = () => authenticatedFetchData(`${API_HOST}/access/v1/region-admin`, {})

export const fetchMyRegions = () => authenticatedFetchData(`${API_HOST}/access/v1/region-admin/myregions`)

export const addRegionAdmin = (userId: string, region: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/region-admin/${userId}/${region}`, {
    method: 'PUT'
  })

export const removeRegionAdmin = (userId: string, region: string) =>
  authenticatedFetchData(`${API_HOST}/access/v1/region-admin/${userId}/${region}`, {
    method: 'DELETE'
  })
