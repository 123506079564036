import React from 'react'
import { Link, LoaderFunction, useParams } from 'react-router-dom'
import { RvmWeeklySummaryType, StoreStatusType } from '../../dataTypes'
import { fetchStoreStatus } from '../../services'
import { ArrowLeftIcon } from '../../icons'
import { AcceptanceRateStatus } from './AcceptanceRateStatus'
import { UptimeRateStatus } from './UptimeRateStatus'
import { CleaningRateStatus } from './CleaningRateStatus'
import { MostRecentUpdate } from './MostRecentUpdate'
import { useLoaderData } from 'react-router'
import { ExportCSV } from '../overall-status/ExportCSV'

type StoreStatusRouteParams = { region: string; locationId: string }

const sortSummaries = (storeStatus: StoreStatusType) => {
  Object.keys(storeStatus.rvmWeeklySummariesMap).forEach(storeId => {
    const rvmWeeklySummaries = storeStatus.rvmWeeklySummariesMap[storeId]
    rvmWeeklySummaries.sort((a: RvmWeeklySummaryType, b: RvmWeeklySummaryType) =>
      // Handle special case for year rollover
      a.weekNumber < 4 && b.weekNumber > 49
        ? -1
        : b.weekNumber < 4 && a.weekNumber > 49
        ? 1
        : b.weekNumber - a.weekNumber
    )
  })
  return storeStatus
}

export const storeStatusLoader: LoaderFunction = async ({ params }) => {
  const { region, locationId } = params as StoreStatusRouteParams
  const storeStatus = await fetchStoreStatus(region, locationId).then(sortSummaries)

  return { storeStatus }
}

export const StoreStatusPage = () => {
  const { region, locationId } = useParams() as StoreStatusRouteParams
  const { storeStatus } = useLoaderData() as { storeStatus: StoreStatusType }

  return (
    <div className="max-w-screen-sm mx-auto">
      <div className="flex flex-row items-start justify-between my-md pr-md">
        <h1>
          <Link className="flex w-max text-lg" to="/">
            <button className="btn btn-icon">
              <ArrowLeftIcon width="1.5rem" height="1.5rem" />
            </button>
            <h1 className="centerFlex text-xl">{storeStatus.storeName}</h1>
          </Link>
        </h1>

        <ExportCSV region={region} locationId={locationId} />
      </div>

      <AcceptanceRateStatus storeStatus={storeStatus} />

      <UptimeRateStatus storeStatus={storeStatus} />

      <CleaningRateStatus storeStatus={storeStatus} />

      {storeStatus.rvmDetails.some(rvm => rvm.mostRecentStats !== undefined) && (
        <MostRecentUpdate rvmDetails={storeStatus.rvmDetails} />
      )}
    </div>
  )
}
