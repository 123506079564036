import { fetchOverallCsv, fetchStoreCsv } from '../../services'
import {
  Common_Cancel,
  Common_ExportData,
  Common_ExportData_Failure,
  Common_Months,
  ExportData_End,
  ExportData_Start,
  ExportData_StartBeforeEndError
} from '../../translations/messages'
import { useIntl } from 'react-intl'
import { FunctionComponent, useEffect, useState } from 'react'
import { AlertIcon } from '../../icons'
import { Dialog } from '../shared'
import { startOfMonth, endOfDay, format, isBefore, subMonths, endOfMonth } from 'date-fns'
import { logError } from '../../lib/logError'
import { ampli } from '../../ampli'
import { weeksBetween } from '../../lib/util'

type Props = {
  region?: string
  locationId?: string
}

export const ExportCSV: FunctionComponent<Props> = ({ region, locationId }) => {
  const intl = useIntl()
  const [data, setData] = useState()
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [error, setError] = useState('')
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'success' | 'failure'>('idle')
  const today = format(Date.now(), 'yyyy-MM-dd')
  const [startDate, setStartDate] = useState(format(startOfMonth(Date.now()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(today)

  const datesAreValid = () => {
    const start = new Date(startDate)
    const end = endOfDay(new Date(endDate))

    if (!isBefore(start, end)) {
      setError(intl.formatMessage(ExportData_StartBeforeEndError))
      return false
    } else {
      setError('')
      return true
    }
  }

  const exportData = async (event: any) => {
    try {
      event.preventDefault()

      if (!datesAreValid()) {
        return
      }

      setError('')
      setRequestStatus('pending')

      const start = new Date(startDate)
      const end = endOfDay(new Date(endDate))
      const startString = start.toISOString()
      const endString = end.toISOString()

      const { csv } =
        region && locationId
          ? await fetchStoreCsv(region, locationId, startString, endString)
          : await fetchOverallCsv(startString, endString)
      setData(csv)
      ampli.storeDataExported({ number_of_weeks: weeksBetween(start, end) })
      setRequestStatus('idle')
    } catch (error: any) {
      if (error.status === 400) {
        setError(error?.body?.detail)
      } else {
        setError(intl.formatMessage(Common_ExportData_Failure))
      }
      logError(new Error(`Failed to export data`), error as Error)
      ampli.storeDataExportFailed()
      setRequestStatus('failure')
    }
  }

  const updateDates = (months: number) => {
    const newStartDate = startOfMonth(subMonths(new Date(), months))
    const newEndDate = subMonths(endOfMonth(new Date()), 1)

    setStartDate(format(newStartDate, 'yyyy-MM-dd'))
    setEndDate(format(newEndDate, 'yyyy-MM-dd'))
  }

  useEffect(() => {
    if (data) {
      setShowExportDialog(false)

      const fileName = region && locationId ? `${region}_${locationId}_data.csv` : 'fleet_portal_data.csv'

      const anchor = document.createElement('a')
      const file = new Blob([data], { type: 'test/csv' })
      anchor.href = URL.createObjectURL(file)
      anchor.ariaLabel = 'Export store data'
      anchor.download = fileName
      const root = document.getElementById('root') as HTMLDivElement
      root.appendChild(anchor)
      anchor.click()
    }
  }, [data, locationId, region])

  return (
    <div className="flex flex-col space-y-md">
      {showExportDialog && (
        <Dialog labeledBy="dialogHeading" describedBy="">
          <form className="card p-lg flex flex-col space-y-md" onSubmit={exportData}>
            <h1 id="dialogHeading" className="text-lg">
              {intl.formatMessage(Common_ExportData)}
            </h1>

            {region && locationId ? (
              <>
                <input type="hidden" name="region" value={region} />
                <input type="hidden" name="locationId" value={locationId} />
              </>
            ) : null}

            <div className="flex space-x-sm">
              <button
                type="button"
                onClick={() => updateDates(1)}
                className={`w-full rounded py-sm text-sm transition active:scale-95 bg-fog`}
              >
                {intl.formatMessage(Common_Months, { value: 1 })}
              </button>
              <button
                type="button"
                onClick={() => updateDates(3)}
                className={`w-full rounded py-sm text-sm transition active:scale-95 bg-fog`}
              >
                {intl.formatMessage(Common_Months, { value: 3 })}
              </button>
              <button
                type="button"
                onClick={() => updateDates(6)}
                className={`w-full rounded py-sm text-sm transition active:scale-95 bg-fog`}
              >
                {intl.formatMessage(Common_Months, { value: 6 })}
              </button>
            </div>

            <div>
              <label htmlFor="startDate" className="label">
                {intl.formatMessage(ExportData_Start)}
              </label>
              <input
                id="startDate"
                type="date"
                name="startDate"
                value={startDate}
                max={today}
                onChange={evt => setStartDate(evt.target.value)}
              />
            </div>

            <div>
              <label htmlFor="endDate" className="label">
                {intl.formatMessage(ExportData_End)}
              </label>
              <input
                id="endDate"
                type="date"
                name="endDate"
                value={endDate}
                max={today}
                onChange={evt => setEndDate(evt.target.value)}
              />
            </div>

            {error && (
              <div className="font-bold text-red-dark flex space-x-sm items-center">
                <AlertIcon width="1rem" height="1rem" />
                <span>{error}</span>
              </div>
            )}

            <div className="flex flex-col space-y-md lg:space-y-0 lg:flex-row lg:space-x-md justify-end">
              <button type="button" className="btn" onClick={() => setShowExportDialog(false)}>
                {intl.formatMessage(Common_Cancel)}
              </button>

              <button
                type="submit"
                className="btn btn-primary-dark"
                disabled={requestStatus === 'pending'}
                aria-disabled={requestStatus === 'pending'}
              >
                <span className="flex items-center justify-center space-x-sm">
                  {requestStatus === 'pending' && <span className="loadingSpinner" style={{ fontSize: '0.7rem' }} />}{' '}
                  <span>{intl.formatMessage(Common_ExportData)}</span>
                </span>
              </button>
            </div>
          </form>
        </Dialog>
      )}
      <button className="btn w-full" onClick={() => setShowExportDialog(true)}>
        {intl.formatMessage(Common_ExportData)}
      </button>
    </div>
  )
}
