import React from 'react'

export const Rate = ({ value, threshold }: { value: number | null; threshold?: number }) => {
  return (
    <>
      {value === null ? (
        <span>-</span>
      ) : !threshold ? (
        <span>{value}%</span>
      ) : (
        <span
          className={
            value < threshold
              ? 'px-sm rounded-half bg-red text-white'
              : value < Math.min(threshold + 5, 90)
              ? 'px-sm rounded-half bg-yellow'
              : 'text-black'
          }
        >
          {value}%
        </span>
      )}
    </>
  )
}
