import React, { useState } from 'react'
import { Outlet, useLoaderData, useNavigation } from 'react-router'
import { fetchUserData } from '../services/user-service'
import { TermsAndConditions } from './TermsAndConditions'
import { Header } from './header'
import { fetchMyRegions } from '../services/access-service'
import { UserType } from '../dataTypes'
import { TemporaryMaintenanceBanner } from './shared/TemporaryMaintenanceBanner'
import { useLocation } from 'react-router-dom'

declare global {
  type RootLoaderData = {
    user: UserType
    myRegions: string[]
  }
}

export const userDataLoader = async () => {
  const user = await fetchUserData()
  const myRegions = await fetchMyRegions()

  return { user, myRegions }
}

export const AppLayout = () => {
  const { user } = useLoaderData() as { user: UserType }

  const { pathname } = useLocation()
  const navigation = useNavigation()

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    window.localStorage.getItem('terms_and_conditions_accepted') === 'true'
  )

  const acceptTermsAndConditions = () => {
    window.localStorage.setItem('terms_and_conditions_accepted', 'true')
    setTermsAndConditionsAccepted(true)
  }

  if (!termsAndConditionsAccepted) {
    return <TermsAndConditions acceptTermsAndConditions={acceptTermsAndConditions} />
  }

  return (
    <>
      <Header />

      {navigation.state === 'loading' ? (
        <div className="fixed w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.3)] z-[1]">
          <div className="card p-lg flex items-center justify-center">
            <div className="loadingSpinner"></div>
          </div>
        </div>
      ) : (
        <>
          {pathname !== '/upload-status' && <TemporaryMaintenanceBanner />}

          {user ? (
            <Outlet />
          ) : (
            <div className="centerFlex mt-lg">
              <div className="loadingSpinner" />
            </div>
          )}
        </>
      )}
    </>
  )
}
