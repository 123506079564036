import { Rate } from '../shared'
import React from 'react'
import { StoreStatusType } from '../../dataTypes'
import { secondsToHoursAndMinutes, secondsToMinutesAndSeconds } from '../../lib/util'
import { RvmStatusTable } from './RvmStatusTable'
import { FormattedMessage } from 'react-intl'
import {
  CleaningRateStatus_AvgDuration,
  CleaningRateStatus_TimeSpent,
  Common_Cleaning,
  Common_Cleanings
} from '../../translations/messages'
import { useRouteLoaderData } from 'react-router-dom'

export const CleaningRateStatus = ({ storeStatus }: { storeStatus: StoreStatusType }) => {
  const {
    cleaningRate,
    cleaningCount,
    expectedCleaningCount,
    totalCleaningDurationInSeconds,
    averageCleaningDurationInSeconds,
    rvmWeeklySummariesMap,
    rvmDetails
  } = storeStatus
  const { user } = useRouteLoaderData('root') as RootLoaderData
  const { cleaningRateThreshold } = user?.settings ?? {}

  const textContainerStyles = `text-center font-bold text-sm`

  return (
    <div className="card m-sm">
      <h2 className="p-lg text-lg">
        <FormattedMessage id={Common_Cleaning.id} defaultMessage={Common_Cleaning.defaultMessage} />
      </h2>
      <div className="flex flex-wrap justify-around">
        <div className="flex flex-col">
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Cleaning.id} defaultMessage={Common_Cleaning.defaultMessage} />
            </div>
            <span className="centerFlex">
              <Rate value={cleaningRate} threshold={cleaningRateThreshold} />
            </span>
          </div>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage id={Common_Cleanings.id} defaultMessage={Common_Cleanings.defaultMessage} />
            </div>
            <span className="centerFlex">
              {cleaningCount}/{expectedCleaningCount}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage
                id={CleaningRateStatus_TimeSpent.id}
                defaultMessage={CleaningRateStatus_TimeSpent.defaultMessage}
              />
            </div>
            <span className="centerFlex">{secondsToHoursAndMinutes(totalCleaningDurationInSeconds)}</span>
          </div>
          <div className="m-sm">
            <div className={textContainerStyles}>
              <FormattedMessage
                id={CleaningRateStatus_AvgDuration.id}
                defaultMessage={CleaningRateStatus_AvgDuration.defaultMessage}
              />
            </div>
            <span className="centerFlex">{secondsToMinutesAndSeconds(averageCleaningDurationInSeconds)}</span>
          </div>
        </div>
      </div>
      <RvmStatusTable rvmWeeklySummariesMap={rvmWeeklySummariesMap} rvmDetails={rvmDetails} field="cleaningRate" />
    </div>
  )
}
